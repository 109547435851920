import { Action, createFeatureSelector, createReducer } from "@ngrx/store";
import { HelpCenterState } from "../../entities";

interface ReducerState extends HelpCenterState {}

const initialState: ReducerState = {
  ui: {},
};

const helpCenterReducer = createReducer(initialState);

export function HelpCenterReducer(state: ReducerState, action: Action) {
  return helpCenterReducer(state, action);
}

export const getHelpCenterState = createFeatureSelector<ReducerState>("helpCenter");
