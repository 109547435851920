import {
  ArbitraryStop,
  CreateOrderReducerState,
  DeliveryDetails,
  DeliveryServicesReq,
  OrderForCreation,
  OrderStopModifier,
  OrderType,
  Product,
  ServiceType,
  User,
} from "@app/modules/create-order/entities/create-order.entities";
import { PickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { PaymentMethodLabel } from "../../constants";

export enum CreateOrderAction {
  ClearAll = "[CREATE-ORDER] Clear all create order",
  UiCreateOrder = "[CREATE-ORDER] UI create order",
  Empty = "[CREATE-ORDER] Empty",
  ChangeStep = "[CREATE-ORDER] Change step",
  CreateOrder = "[CREATE-ORDER] Create order",
  ModifyOrderId = "[CREATE-ORDER] Add order id",
  ModifyTotalValue = "[CREATE-ORDER] Modify total value",
  ModifyDropOff = "[CREATE-ORDER] Modify Drop Off",
  ModifyOrderStop = "[CREATE-ORDER] Modify order stop",
  AddOrderStop = "[CREATE-ORDER] Create order stop",
  FetchDeliveryTypes = "[CREATE-ORDER] Fetch delivery types",
  SetDeliveryTypes = "[CREATE-ORDER] Set delivery types",
  ModifyDetails = "[CREATE-ORDER] Modify order details",
  ModifyRappiOrderId = "[CREATE-ORDER] Modify rappi order id",
  ModifyUser = "[CREATE-ORDER] Modify user",
  InitOrderStops = "[CREATE-ORDER] Init Stops",
  DeleteStop = "[CREATE-ORDER] Delete Stop",
  ModifyPaymentMethod = "[CREATE-ORDER] Modify Payment Method",
  ModifyTerminalPickingPoint = "[CREATE-ORDER] Modify Terminal Picking Point",
  SetEstimatedPrice = "[CREATE-ORDER] Set estimated price",
}

export const clearAll = createAction(CreateOrderAction.ClearAll);
export const uiCreateOrder = createAction(
  CreateOrderAction.UiCreateOrder,
  props<{ ui: Array<AdapterUi<CreateOrderReducerState["ui"]>> }>()
);
export const Empty = createAction(CreateOrderAction.Empty);
export const changeStep = createAction(CreateOrderAction.ChangeStep, props<{ step: number }>());
export const createOrder = createAction(CreateOrderAction.CreateOrder, props<{ order: OrderForCreation; orderType: OrderType }>());
export const addOrderStop = createAction(CreateOrderAction.AddOrderStop);
export const modifyOrderId = createAction(CreateOrderAction.ModifyOrderId, props<{ orderId: string }>());
export const modifyRappiOrderId = createAction(CreateOrderAction.ModifyRappiOrderId, props<{ rappiOrderId: string }>());
export const modifyTotalValue = createAction(CreateOrderAction.ModifyTotalValue, props<{ totalValue: number }>());
export const modifyDropOff = createAction(CreateOrderAction.ModifyDropOff, props<{ dropOff: Partial<ArbitraryStop> }>());
export const modifyOrderStop = createAction(CreateOrderAction.ModifyOrderStop, props<OrderStopModifier>());
export const modifyTerminalPickingPoint = createAction(
  CreateOrderAction.ModifyTerminalPickingPoint,
  props<{ terminalPickingPoint: number | undefined }>()
);
export const fetchDeliveryTypes = createAction(
  CreateOrderAction.FetchDeliveryTypes,
  props<{ order: DeliveryServicesReq; orderType: OrderType; paymentMethod: PaymentMethodLabel; terminalPickingPoint?: number }>()
);
export const setDeliveryTypes = createAction(CreateOrderAction.SetDeliveryTypes, props<{ payload: ServiceType[] }>());
export const setEstimatedPrice = createAction(CreateOrderAction.SetEstimatedPrice, props<{ payload: number }>());
export const modifyDetails = createAction(CreateOrderAction.ModifyDetails, props<{ details: Partial<DeliveryDetails> }>());
export const modifyUser = createAction(CreateOrderAction.ModifyUser, props<{ user: Partial<User> }>());
export const modifyPaymentMethod = createAction(CreateOrderAction.ModifyPaymentMethod, props<{ paymentMethod: PaymentMethodLabel }>());
export const initOrderStops = createAction(
  CreateOrderAction.InitOrderStops,
  props<{
    orderType: OrderType;
    pickingPoint?: PickingPoint;
    products?: Product[];
    userInstructions?: string;
    ppInstructions: string;
  }>()
);
export const deleteStop = createAction(CreateOrderAction.DeleteStop, props<{ id: number }>());
