import { HttpClient } from "@angular/common/http";
import { environment as ENV } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { CustomHttpTranslateLoader } from "./custom-translate-loader.util";

const url = `/assets/i18n/${ENV.lang}.json?version=${ENV.i18n_version}`;
const fallback = `/assets/i18n/es.json?version=${ENV.i18n_version}`;

export function createTranslateLoader(http: HttpClient): CustomHttpTranslateLoader {
  return new CustomHttpTranslateLoader(http, url, fallback);
}

export function setTranslate(translate: TranslateService): () => Promise<any> {
  return (): Promise<any> => {
    const i18nFile = "configJson";
    translate.setDefaultLang(i18nFile);
    translate.use(i18nFile);
    return Promise.resolve({});
  };
}
