import { createAction, props } from "@ngrx/store";

export enum LandingAction {
  SendLead = "[LANDING] Send lead",
  EraseData = "[LANDING] Erase data",
}

export const eraseData = createAction(LandingAction.EraseData);
export const sendLead = createAction(
  LandingAction.SendLead,
  props<{ name: string; email: string; phone: string; businessType: string; orderEstimate: string }>()
);
