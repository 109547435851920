import { track } from "@amplitude/analytics-browser";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CargoAuthResponse } from "@app/modules/auth/entities";
import { AuthFacade } from "@app/modules/auth/facade/auth.facade";
import { localStorageKey } from "@core/constants";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

export const IGNORE_401_HEADER = "x-ignore-401";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private storage: StorageMap, private authModel: AuthFacade) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.storage.get(localStorageKey).pipe(
      switchMap((authInfo: CargoAuthResponse) => {
        if (authInfo?.access_token) {
          request = request.clone({ setHeaders: { "x-auth-token": authInfo?.access_token } });
        }

        request = request.clone({ setHeaders: { Accept: "application/json" } });

        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            track("ERROR", { error });
            // Algunos endpoints de autenticación externa (MiTienda, Partner Portal, etc) envían 401.
            // Pero no debería hacer logout del todo
            if (error.status === 401 && !request.headers.has(IGNORE_401_HEADER)) {
              this.authModel.doLogout();
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
