import { BasicRetailer, CheckIn, DragData, GlobalState, PickingPoint, Retailer, SerializedPickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";

export enum GlobalAction {
  ClearAll = "[GLOBAL] Clear all global",
  UiGlobal = "[GLOBAL] UI Global",
  Empty = "[GLOBAL] Empty",
  FetchComms = "[GLOBAL] Fetch Communications",
  SetComms = "[GLOBAL] Set Communications",
  SetCheckin = "[GLOBAL] Set CheckIn data",
  FetchCheckin = "[GLOBAL] Fetch CheckIn data",
  FetchAllCheckin = "[GLOBAL] Fetch All CheckIn data",
  FetchPickingPoints = "[GLOBAL] Fetch Picking Points",
  SetSerializedPickingPoints = "[GLOBAL] Set Serialized Picking Points",
  SetPickingPoints = "[GLOBAL] Set Picking Points",
  SetDragCoords = "[GLOBAL] Set drag coords",
  SetRetailers = "[GLOBAL] Set retailers",
  GetRetailers = "[GLOBAL] Get retailers",
  FetchRetailers = "[GLOBAL] Fetch retailer info",
  SetCurrentRetailer = "[GLOBAL] Set retailer info",
  GetCurrentRetailer = "[GLOBAL] Get retailer info",
  RefreshCurrentRetailer = "[GLOBAL] Refresh retailer info",
  FetchInitialData = "[HELP CENTER] Fetch initial data",
  SetInitialData = "[HELP CENTER] Set initial data",
  FetchIsCashless = "[HELP CENTER] Fetch is retailer cashless",
  SetSettlementAttribute = "[HELP CENTER] Set Settlement Attribute",
}

export const clearAll = createAction(GlobalAction.ClearAll);
export const uiGlobal = createAction(GlobalAction.UiGlobal, props<{ ui: Array<AdapterUi<GlobalState["ui"]>> }>());
export const Empty = createAction(GlobalAction.Empty);
export const fetchComms = createAction(GlobalAction.FetchComms, props<{ retailer: Retailer }>());
export const setComms = createAction(GlobalAction.SetComms, props<{ payload: GlobalState["comms"] }>());
export const fetchPickingPoints = createAction(
  GlobalAction.FetchPickingPoints,
  props<{ ascendingName?: boolean; activeOnly?: boolean; retailerId?: number }>()
);
export const setSerializedPickingPoints = createAction(
  GlobalAction.SetSerializedPickingPoints,
  props<{ payload: SerializedPickingPoint[] }>()
);
export const setPickingPoints = createAction(GlobalAction.SetPickingPoints, props<{ payload: PickingPoint[] }>());
export const setDragCoords = createAction(GlobalAction.SetDragCoords, props<{ payload: DragData }>());
export const setCheckin = createAction(GlobalAction.SetCheckin, props<{ checkin: CheckIn }>());
export const fetchCheckin = createAction(GlobalAction.FetchCheckin, props<{ pickingPointId: number }>());
export const fetchAllCheckin = createAction(GlobalAction.FetchAllCheckin);
export const setRetailers = createAction(GlobalAction.SetRetailers, props<{ retailers: BasicRetailer[] }>());
export const getRetailers = createAction(GlobalAction.GetRetailers);
export const setCurrentRetailer = createAction(GlobalAction.SetCurrentRetailer, props<{ retailer: Retailer }>());
export const getCurrentRetailer = createAction(GlobalAction.GetCurrentRetailer, props<{ retailerId: number }>());
export const refreshCurrentRetailer = createAction(GlobalAction.RefreshCurrentRetailer);
export const fetchRetailer = createAction(GlobalAction.FetchRetailers);
export const fetchIsCashless = createAction(GlobalAction.FetchIsCashless, props<{ retailerId: number }>());
export const fetchInitialData = createAction(GlobalAction.FetchInitialData);
export const setInitialData = createAction(GlobalAction.SetInitialData, props<{ hasOrders: boolean; hasPickingPoints: boolean }>());
export const setSettlementAttribute = createAction(
  GlobalAction.SetSettlementAttribute,
  props<{ settlement: Partial<GlobalState["settlement"]> }>()
);
