import { PickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { UpsertPickingPointState } from "../../entities/picking-points.entities";

export enum PickingPointAction {
  ClearAll = "[UPSERT-UPSERT-PICKING-POINTS] Clear all picking-points",
  UiPickingPoints = "[UPSERT-PICKING-POINTS] UI picking-points",
  Empty = "[UPSERT-PICKING-POINTS] Empty",
  CreatePickingPoint = "[UPSERT-PICKING-POINTS] Create PP",
  SetPickingPoint = "[PICKING-POINTS] Set Picking point",
  UpdatePickingPoint = "[UPSERT-PICKING-POINTS] Update PP",
  FetchPickingPointById = "[UPSERT-PICKING-POINTS] Fetch Picking point by Id",
  SuccessfulUpsert = "[UPSERT-PICKING-POINTS] Successful Upsert",
}

export const clearAll = createAction(PickingPointAction.ClearAll);
export const uiPickingPoints = createAction(
  PickingPointAction.UiPickingPoints,
  props<{ ui: Array<AdapterUi<UpsertPickingPointState["ui"]>> }>()
);
export const Empty = createAction(PickingPointAction.Empty);

export const setPickingPoint = createAction(PickingPointAction.SetPickingPoint, props<{ payload: PickingPoint }>());
export const createPickingPoint = createAction(
  PickingPointAction.CreatePickingPoint,
  props<{ payload: PickingPoint; file: File | undefined }>()
);
export const updatePickingPoint = createAction(
  PickingPointAction.UpdatePickingPoint,
  props<{ payload: PickingPoint; file: File | undefined }>()
);
export const fetchPickingPointById = createAction(PickingPointAction.FetchPickingPointById, props<{ payload: number }>());
export const successfullUpsert = createAction(PickingPointAction.SuccessfulUpsert);
