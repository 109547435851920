import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthenticationGuard, UnAuthenticatedGuard } from "./index";
import { PermissionGuard } from "./permission.guard";
import { RegionGuard } from "./region.guard";

// Guards
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [UnAuthenticatedGuard, AuthenticationGuard, PermissionGuard, RegionGuard],
})
export class GuardsModule {}
