import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { CoreStore, RappiSyncState, StoreOrigin, UnsuccessfulStoreImport } from "../../entities/rappi-sync.entities";

export enum RappiSyncAction {
  ClearAll = "[RAPPI SYNC] Clear all Rappi Sync data",
  SetUi = "[RAPPI SYNC] Set RappiSync UI",
  GetStoresFromPartnerPortal = "[RAPPI SYNC] Get Stores From Partner Portal",
  SetStoresFromPartnerPortal = "[RAPPI SYNC] Set Stores From Partner Portal",
  GetStoresFromMiTienda = "[RAPPI SYNC] Get Stores From MiTienda",
  SetStoresFromMiTienda = "[RAPPI SYNC] Set Stores From MiTienda",
  ImportStoresFromMiTienda = "[RAPPI SYNC] Import Stores From MiTienda",
  ImportStoresFromPartnerPortal = "[RAPPI SYNC] Import Stores From PartnerPortal",
  SetStoreOrigin = "[RAPPI SYNC] Set Store Origin",
  SetUnsuccessfulStores = "[RAPPI SYNC] Set Unsuccessful Stores",
  SetSuccessfulStores = "[RAPPI SYNC] Set Successful Stores",
}

export const clearAll = createAction(RappiSyncAction.ClearAll);
export const setUi = createAction(RappiSyncAction.SetUi, props<{ ui: Array<AdapterUi<RappiSyncState["ui"]>> }>());

export const getStoresFromPartnerPortal = createAction(RappiSyncAction.GetStoresFromPartnerPortal, props<{ ppToken: string }>());
export const setStoresFromPartnerPortal = createAction(RappiSyncAction.SetStoresFromPartnerPortal, props<{ stores: CoreStore[] }>());
export const setStoreOrigin = createAction(RappiSyncAction.SetStoreOrigin, props<{ origin: StoreOrigin }>());
export const setUnsuccessfulStores = createAction(RappiSyncAction.SetUnsuccessfulStores, props<{ stores: UnsuccessfulStoreImport[] }>());
export const setSuccessfulStores = createAction(RappiSyncAction.SetSuccessfulStores, props<{ stores: number[] }>());
export const getStoresFromMiTienda = createAction(RappiSyncAction.GetStoresFromMiTienda, props<{ username: string; password: string }>());
export const setStoresFromMiTienda = createAction(
  RappiSyncAction.SetStoresFromMiTienda,
  props<{ stores: CoreStore[]; token: string | undefined }>()
);
export const importStoresFromMiTienda = createAction(
  RappiSyncAction.ImportStoresFromMiTienda,
  props<{ storeIds: number[]; retailerId: number; mtToken: string }>()
);
export const importStoresFromPartnerPortal = createAction(
  RappiSyncAction.ImportStoresFromPartnerPortal,
  props<{ storeIds: number[]; retailerId: number; ppToken: string }>()
);
