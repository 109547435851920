import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

// Interfaces
import { CargoAuthResponse, LoginFormData, PasswordResetBody, RetailerInfo } from "@app/modules/auth/entities";

// Consts
import { environment as ENV } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CargoAuthService {
  constructor(private http: HttpClient) {}

  login(formdata: LoginFormData) {
    return this.http.post(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}login`, formdata);
  }

  createRetailer(values: RetailerInfo): Observable<any> {
    return this.http.post(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}retailer`, values);
  }

  passwordReset(values: PasswordResetBody): Observable<any> {
    return this.http.post(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}auth-reset-password`, values);
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}auth-reset-password-request`, { email });
  }

  fetchRetailerIds(resp: CargoAuthResponse): Observable<any> {
    return this.http.get(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}user/user-info`, {
      headers: {
        "x-auth-token": resp.access_token,
      },
    });
  }
}
