import { AnalyticsReducer } from "@app/modules/analytics/store/reducers/analytics.reducer";
import { CompanySettingsReducer } from "@app/modules/company-settings/store/reducers/company-settings.reducer";
import { ControlTowerReducer } from "@app/modules/control-tower/store/reducers/control-tower.reducer";
import { CreateOrderErrorReducer } from "@app/modules/create-order/store/reducers/errors.create-order.reducer";
import { DevPortalReducer } from "@app/modules/dev-portal/store/reducers/dev-portal.reducer";
import { HelpCenterReducer } from "@app/modules/help-center/store/reducers/help-center.reducer";
import { OrdersReducer } from "@app/modules/orders/store/reducers/order-reducer";
import { PickingPointsReducer } from "@app/modules/picking-points/store/reducers/picking-points.reducer";
import { RappiSyncReducer } from "@app/modules/rappi-sync/store/reducers/rappi-sync.reducer";
import { ReportsReducer } from "@app/modules/reports/store/reducers/reports.reducer";
import { SettlementReducer } from "@app/modules/settlement/store/reducers/settlement.reducer";
import { CreatePickingPointErrorReducer } from "@app/modules/upsert-picking-point/store/reducers/errors.picking-points.reducer";
import { UpsertPickingPointReducer } from "@app/modules/upsert-picking-point/store/reducers/upsert-picking-point.reducer";
import { UsersReducer } from "@app/modules/users/store/reducers/users.reducer";
import { GlobalReducer } from "@core/store/reducers/global.reducer";
import { authReducer } from "@modules/auth/store/reducers/auth.reducer";
import { CreateOrderReducer } from "@modules/create-order/store/reducers/create-order.reducer";
import { LandingReducer } from "@modules/landing/store/reducers/landing.reducer";

export default {
  activeOrders: OrdersReducer,
  auth: authReducer,
  companySettings: CompanySettingsReducer,
  createOrder: CreateOrderReducer,
  createOrderErrors: CreateOrderErrorReducer,
  global: GlobalReducer,
  devPortal: DevPortalReducer,
  pickingPoints: PickingPointsReducer,
  users: UsersReducer,
  reports: ReportsReducer,
  settlement: SettlementReducer,
  createPickingPointErrors: CreatePickingPointErrorReducer,
  analytics: AnalyticsReducer,
  upsertPickingPoint: UpsertPickingPointReducer,
  landing: LandingReducer,
  helpCenter: HelpCenterReducer,
  rappiSync: RappiSyncReducer,
  controlTower: ControlTowerReducer,
};
