import { UpsertPickingPointState } from "@app/modules/upsert-picking-point/entities/picking-points.entities";
import * as PickingPointsAction from "@app/modules/upsert-picking-point/store/actions/upsert-picking-point.action";
import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

const initialState: UpsertPickingPointState = {
  ui: {
    deleteSuccess: false,
    hasData: false,
    isForm: false,
    isLoading: true,
    isFetchingCoreStores: false,
    isImportingData: false,
    isBatchUpdating: false,
    isModalDeleteShow: false,
    loaderBtn: false,
    succesfulImport: false,
  },
  selected_pp: null,
};

const upsertPickingPointReducer = createReducer(
  initialState,
  on(PickingPointsAction.clearAll, () => initialState),
  on(PickingPointsAction.setPickingPoint, (state, { payload }) => {
    return {
      ...state,
      selected_pp: payload,
    };
  }),
  on(PickingPointsAction.uiPickingPoints, (state, { ui }) => GlobalAdapter.setUi(ui, state))
);

export function UpsertPickingPointReducer(state: UpsertPickingPointState, action: Action) {
  return upsertPickingPointReducer(state, action);
}

export const getPickingPoints = createFeatureSelector<UpsertPickingPointState>("upsertPickingPoint");
export const getUiPickingPoints = createSelector(getPickingPoints, ({ ui }) => ui);
export const getSelectedPickingPoint = createSelector(getPickingPoints, ({ selected_pp }) => selected_pp);
