import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import * as actions from "@modules/company-settings/store/actions/company-settings.action";
import { CompanySettingsState } from "../../entities";

export const authInitialState: CompanySettingsState = {
  ui: {
    loading: false,
  },
  pickingPoints: [],
  pendingChanges: false,
  checkinStoresAmount: 0,
};

const companySettingsReducer = createReducer(
  authInitialState,
  on(actions.clearAll, () => ({ ...authInitialState })),
  on(actions.uiCompanySettings, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(actions.modifyPendingChanges, (state, { pendingChanges }) => ({ ...state, pendingChanges })),
  on(actions.setCheckinStoresAmount, (state, { checkinStoresAmount }) => ({ ...state, checkinStoresAmount }))
);

export function CompanySettingsReducer(state, action) {
  return companySettingsReducer(state, action);
}

export const getCompanySettingsStore = createFeatureSelector<CompanySettingsState>("companySettings");

export const getCompanySettingsUi = createSelector(getCompanySettingsStore, ({ ui }) => ui);
export const getPickingPoints = createSelector(getCompanySettingsStore, ({ pickingPoints }) => pickingPoints);
export const getPendingChanges = createSelector(getCompanySettingsStore, ({ pendingChanges }) => pendingChanges);
export const getCheckinStoresAmount = createSelector(getCompanySettingsStore, ({ checkinStoresAmount }) => checkinStoresAmount);
