import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { retailersStorageKey } from "@core/constants";
import { BasicRetailer } from "@core/entities";
import { StorageMap } from "@ngx-pwa/local-storage";

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(protected router: Router, private storage: StorageMap) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const role: string[] = route.data.roles as string[];

    return this.storage.get(retailersStorageKey).pipe(
      tap((retailers: BasicRetailer[]) => {
        if (!role?.includes(retailers[0].role)) {
          this.router.navigate(["/dashboard/active-orders"]);
        }
      }),
      map((retailers: BasicRetailer[]) => role?.includes(retailers[0].role))
    );
  }
}
