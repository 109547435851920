import { init } from "@amplitude/analytics-browser";
import { Component, OnInit } from "@angular/core";
import { environment } from "@environments/environment";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    init(environment.amplitude.key, undefined, {
      defaultTracking: {
        pageViews: true
      }
    });
  }
}
