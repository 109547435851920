import { IError } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { UpsertUserBody, User, UsersState } from "@modules/users/entities";
import { createAction, props } from "@ngrx/store";

export enum UsersAction {
  ClearAll = "[USERS] Clear All Users",
  UiUsers = "[USERS] UI Users",
  Empty = "[USERS] Empty",
  FetchUsers = "[USERS] Fetch Users",
  FetchUserById = "[USERS] Fetch User by Id",
  SetUsers = "[USERS] Set Users",
  SetUpsertUser = "[USERS] Set Upsert User",
  SetUpsertPickingPoints = "[USERS] Set Upsert PickingPoints",
  ModifySelectedRetailers = "[USERS] Modify Selected Retailers",
  CreateUser = "[USERS] Create User",
  UpdateUser = "[USERS] Update User",
  UpdateUserStatus = "[USERS] Update User Status",
  DeleteUser = "[USERS] Delete User",
  AddOneError = "[USERS] Add one error",
  RemoveOneError = "[USERS] Remove one error",
  SetManyErrors = "[USERS] Set many errors",
  WipeUpsertState = "[USERS] Wipe Upsert State",
}

export const clearAll = createAction(UsersAction.ClearAll);
export const wipeUpsertState = createAction(UsersAction.WipeUpsertState);
export const uiUsers = createAction(UsersAction.UiUsers, props<{ ui: Array<AdapterUi<UsersState["ui"]>> }>());
export const Empty = createAction(UsersAction.Empty);

export const fetchUsers = createAction(UsersAction.FetchUsers);
export const fetchUserById = createAction(UsersAction.FetchUserById, props<{ payload: number }>());
export const setUsers = createAction(UsersAction.SetUsers, props<{ payload: User[] }>());
export const setUpsertUser = createAction(
  UsersAction.SetUpsertUser,
  props<{ payload: Partial<UsersState["upsertUserBasicAttributes"]> }>()
);
export const createUser = createAction(UsersAction.CreateUser, props<{ payload: UpsertUserBody }>());
export const updateUserStatus = createAction(
  UsersAction.UpdateUserStatus,
  props<{ payload: Partial<UsersState["upsertUserBasicAttributes"]> }>()
);
export const updateUser = createAction(UsersAction.UpdateUser, props<{ payload: UpsertUserBody }>());
export const setUpsertRetailers = createAction(UsersAction.ModifySelectedRetailers, props<{ payload: number[] }>());
export const setUpsertPickingPoints = createAction(
  UsersAction.SetUpsertPickingPoints,
  props<{ payload: UsersState["upsertUserPickingPoints"] }>()
);
export const deleteUser = createAction(UsersAction.DeleteUser, props<{ id: number }>());
export const addOneError = createAction(UsersAction.AddOneError, props<{ error: IError }>());
export const removeOneError = createAction(UsersAction.RemoveOneError, props<{ id: IError["id"] }>());
export const setManyErrors = createAction(UsersAction.SetManyErrors, props<{ errors: IError[] }>());
