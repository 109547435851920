import { CancelationReasons } from "@app/modules/orders/constants";
import { EntityState } from "@ngrx/entity";

export interface SettlementState {
  ui: SettlementUi;
  successfulOrders: SettlementOrder[];
  selectedOrderId: string | undefined;
  range: { from: string | undefined; to: string | undefined } | undefined;
  searchInput: string;
  page: number;
  orderType: "SUCCESSFULL" | "CANCELLED";
  orderDetail?: any;
  orderRevisionDetail?: any;
  billingPeriods?: BillingEntities;
  billingEmails?: string[];
  revision: { reason: OrderRevisionReason | undefined; comment: string } | undefined;
  salesSummary: {
    summaries: SalesSummary[];
    page: number;
  };
  batchConciliationResponse: any;
}

export interface BillingPickingPoint {
  name: string;
  cargo_id: number;
  billing_rappi_store_id: number;
}

export type BillingPeriodGroupEntity = {
  contractId: number;
  pickingPoints: BillingPickingPoint[];
  frequencyType: FrequencyType;
  periods: EntityBillingPeriod[];
  paymentReferenceId: number;
};

export enum FrequencyType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  WEEKLY_SATURDAY_FRIDAY = "WEEKLY_SATURDAY_FRIDAY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
}

export type EntityBillingPeriod = { firstDay: string; billingPeriods: Omit<BillingPeriod, "contract_id" | "picking_points">[] };
export interface BillingPeriod {
  end_date: string;
  first_order_date: string;
  id: number;
  last_order_date: string;
  num_orders: number;
  retention_date: string;
  start_date: string;
  contract_id: number;
  picking_points: BillingPickingPoint[];
  total_charge: number;
}

interface BillingEntities extends EntityState<BillingPeriodGroupEntity> {
  total: number;
}

export interface SettlementUi {
  lastPage: boolean;
  isLoading: boolean;
  isUploadingBatchConciliation: boolean;
}

export enum CancellationMoment {
  RT_UNASSIGNED = "RT_UNASSIGNED",
  RT_TO_STORE = "RT_TO_STORE",
  RT_IN_STORE = "RT_IN_STORE",
  RT_TO_USER = "RT_TO_USER",
  RT_AT_USER = "RT_AT_USER",
}

export enum OrderRevisionReason {
  BASE_CHARGE_INCORRECT = "BASE_CHARGE_INCORRECT",
  DISTANCE_CHARGE_INCORRECT = "DISTANCE_CHARGE_INCORRECT",
  WT_CHARGE_INCORRECT = "WT_CHARGE_INCORRECT",
  FINISHED_BUT_UNDELIVERED = "FINISHED_BUT_UNDELIVERED",
  FINISHED_BUT_RETURNED = "FINISHED_BUT_RETURNED",
  CANCELLED_BUT_FINISHED = "CANCELLED_BUT_FINISHED",
  RETURNED_BUT_CANCELLED = "RETURNED_BUT_CANCELLED",
  CANCELATION_CHARGE_INCORRECT = "CANCELATION_CHARGE_INCORRECT",
  INCORRECT_STATUS_ID = "INCORRECT_STATUS_ID",
  OTHER = "OTHER",
}

export enum OrderRevisionStatus {
  IN_REVIEW = "IN_REVIEW",
  REQUEST_ACTION = "REQUEST_ACTION",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface CreateOrderRevisionInput {
  order_id: number;
  reason: OrderRevisionReason;
  comment: string;
  attachments: readonly File[];
}

export interface AdditionalRevisionInfoInput {
  order_revision_id: number;
  comment: string;
  attachments: File[];
}

export type SettlementOrder = {
  id: number;
  rappi_order_id: number;
  external_order_id: string;
  retailer_id: number;
  base_order_charge: number;
  rt_distance: number;
  additional_order_distance_charge: number;
  collect_order_cash_charge: number;
  waiting_time_order: number;
  waiting_time_charge: number;
  total_collected: number;
  calculated_tip: number;
  default_tip: number;
  user_tip: number;
  default_tip_charge: number;
  preparation_time: number;
  cancelation_moment: string;
  created_at: string;
  cancel_reason: CancelationReasons;
  cancelation_charge: number;
  needs_car_charge: number;
  needs_car: false;
  picking_charge: number;
  total_order_charge: number;
  compensation_value: number;
  peak_hour_charge: number;
  peak_day_charge: number;
  order_status_id: number;
  collect_cash: number;
  rt_earnings: number;
  total_tax_base_charge: number;
  picking_point_id: number;
  picking_point_name: string;
  t_total: number;
  t_rappi: number;
  t2_assign: number;
  t2_store: number;
  t_in_store: number;
  t2_user: number;
  t2_cancel: number;
  wt_after_cooking_time_ends: number;
  chargeable_waiting_time: number;
  goal_discount: number;
  rappi_store_id: number;
  billing_rappi_store_id: number;
  origin: string;
  final_charge: number;
  taxes: any;
  send_to_partner_payment_date: string;
  cancel_tipification: string;
  billing_period_id: number;
  order_revisions: any[];
};

export interface SalesSummary {
  id: number;
  retailer_id: number;
  created_at: string;
  is_current: boolean;
  report_location: string;
  report_date: string;
  sent_email_id: string;
}
