import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "@auth0/auth0-angular";
import { environment as env } from "@environments/environment";
// Modules
import { AppRoutingModule } from "@app/app-routing.module";
import { GOOGLE_MAPS_CDN } from "@core/constants";
import { CoreModule } from "@core/core.module";

// Consts
import { MICRO_FRONTENDS, STYLES } from "@core/constants";
import { environment } from "@environments/environment";

// Components
import { AppComponent } from "./app.component";

// Services
import { WebAdapterService } from "@core/services/web-adapter.service";

import { registerLocaleData } from "@angular/common";
import localesAR from "@angular/common/locales/es-AR";
import localesCL from "@angular/common/locales/es-CL";
import localesCO from "@angular/common/locales/es-CO";
import localesCR from "@angular/common/locales/es-CR";
import localesEC from "@angular/common/locales/es-EC";
import localesMX from "@angular/common/locales/es-MX";
import localesPE from "@angular/common/locales/es-PE";
import localesUY from "@angular/common/locales/es-UY";
import localesBR from "@angular/common/locales/pt";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    AuthModule.forRoot({
      domain: env.auth0.domain,
      clientId: env.auth0.client_id,
      cacheLocation: "localstorage",
      authorizationParams: {
        redirect_uri: `${window.location.origin}/rappi-sync`,
      },
    }),
  ],
  providers: [WebAdapterService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  private microfront = MICRO_FRONTENDS[environment.placement].google_maps;
  private styles = STYLES[environment.placement].wc_styles;

  constructor(private waService: WebAdapterService) {
    this.waService.initElements([GOOGLE_MAPS_CDN]);

    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.forEach(reg => {
        reg.unregister();
      });
    });

    registerLocaleData(this.getLocale());

    setTimeout(() => {
      this.waService.initElements([this.microfront]);
      this.waService.loadStyles([this.styles]);
    }, 1000);
  }

  getLocale() {
    switch (environment.code) {
      case "AR":
        return localesAR;
      case "BR":
        return localesBR;
      case "CL":
        return localesCL;
      case "CO":
        return localesCO;
      case "CR":
        return localesCR;
      case "EC":
        return localesEC;
      case "MX":
        return localesMX;
      case "PE":
        return localesPE;
      case "UY":
        return localesUY;
      case "DEV":
        return localesCO;
      default:
        return localesCO;
    }
  }
}
