import { environment as ENV } from "@environments/environment";
export * from "./microfront.constants";
export * from "./styles.constants";

export const localStorageKey = "__app_storage__";
export const retailersStorageKey = "__retailers__";
export const userSessionStorageKey = "__userid__";
export const currentRetailerStorageKey = "__current_retailer__";

export const FILTER_NO_RETAILERS = "__all_retailers__";

export const STORE_KEY = "store";
export const PICKUP_ID = "picking_point_id";
export const ADDRESS_KEY = "address";
export const PRODUCTS = "products";
export const FT_EXP_PICKER = "EXPRESS_PICKER";
export const FT_ECO_CARGO = "ECOMMERCE_CARGO";
export const GOOGLE_MAPS_CDN = {
  name: "library_maps",
  src: `https://maps.googleapis.com/maps/api/js?key=${ENV.maps_api.api_key}&callback=&libraries=places,geometry`,
};
export const GOOGLE_MAPS = "google-maps";

export const RETAIL_USER_ID_LENGTH = 18;
