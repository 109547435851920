import { Identify, identify, setUserId } from "@amplitude/analytics-browser";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { localStorageKey, userSessionStorageKey } from "@core/constants";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { StorageMap } from "@ngx-pwa/local-storage";
import { combineLatest, of } from "rxjs";
import { catchError, delay, mergeMap, tap } from "rxjs/operators";

// Actions
import * as globalActions from "@core/store/actions/global.action";
import * as actions from "@modules/auth/store/actions/login.action";

// Services
import { CargoAuthService } from "@modules/auth/services/auth.service";

// Interfaces
import { HttpErrorResponse } from "@angular/common/http";
import { JwtSchema } from "@core/entities";
import { PopUpNotificationService } from "@core/services/popup-notification.service";
import { CargoAuthResponse } from "@modules/auth/entities";
import { TranslateService } from "@ngx-translate/core";
import jwtDecode from "jwt-decode";

@Injectable()
export class CargoAuthEffects {
  constructor(
    private actions$: Actions,
    private authService: CargoAuthService,
    private storage: StorageMap,
    private router: Router,
    private translate: TranslateService,
    private popupService: PopUpNotificationService
  ) {}

  AuthResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginAction),
      mergeMap(({ formdata }) =>
        this.authService.login(formdata).pipe(
          mergeMap((resp: CargoAuthResponse) => {
            return [actions.fetchRetailerIds({ resp })];
          }),
          catchError((httpError: HttpErrorResponse) => {
            const { status, error } = httpError;
            console.error({ status, error });
            this.popupService.error(error);
            return of(actions.empty());
          })
        )
      )
    )
  );

  CreateRetailer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createRetailer),
      mergeMap(({ payload }) =>
        this.authService.createRetailer(payload).pipe(
          mergeMap(() => {
            this.popupService.success({ message: "Cuenta creada exitosamente" });
            return [
              actions.uiAuth({
                ui: [{ name: "loadBtn", value: false }],
              }),
              actions.goLogin(),
            ];
          }),
          catchError((httpError: HttpErrorResponse) => {
            console.error({ httpError });
            const { error } = httpError;
            this.popupService.error(error);
            return of(actions.uiAuth({ ui: [{ name: "loadBtn", value: false }] }));
          })
        )
      )
    )
  );

  FetchRetailerIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchRetailerIds),
      mergeMap(({ resp }) => {
        return this.authService.fetchRetailerIds(resp).pipe(
          mergeMap(({ retailers }: any) => {
            const ans: any[] = [
              actions.respSuccessAction({ payload: { auth: resp, redirect: true } }),
              globalActions.setRetailers({ retailers }),
            ];

            if (retailers.length === 1) {
              ans.push(globalActions.getCurrentRetailer({ retailerId: retailers[0].id }));
            }
            return ans;
          })
        );
      })
    )
  );

  PasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.passwordReset),
      mergeMap(({ payload }) => {
        return this.authService.passwordReset(payload).pipe(
          tap(() => {
            this.popupService.success({ message: this.translate.instant("AUTH.PASSWORD_RESET.SUCCESS") });
          }),
          delay(3000),
          tap(() => {
            this.router.navigate(["/auth/login"]);
          }),
          catchError(e => {
            this.popupService.error(e.error);
            return [];
          })
        );
      })
    )
  );

  RequestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.requestPasswordReset),
      mergeMap(({ email }) => {
        return this.authService.requestPasswordReset(email).pipe(
          mergeMap(() => {
            this.popupService.success({ message: this.translate.instant("AUTH.FORGOT_PASSWORD.SUCCESS") });
            return [actions.uiAuth({ ui: [{ name: "openResetPasswordModal", value: false }] })];
          }),
          catchError(_e => {
            this.popupService.error({ message: this.translate.instant("ERRORS.OTHER") });
            return [];
          })
        );
      })
    )
  );

  Auth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.respSuccessAction),
        mergeMap(({ payload }) => {
          const jwt: JwtSchema = jwtDecode(payload.auth.access_token);
          setUserId(jwt.email + "");
          const identifyEvent = new Identify();
          identifyEvent.append("email", jwt.email);
          identifyEvent.append("name", jwt.name);
          identifyEvent.append("brand_id", jwt.brand_id);
          identify(identifyEvent);

          return combineLatest([
            this.storage.set(userSessionStorageKey, jwt.id),
            this.storage.set(localStorageKey, { ...payload.auth, loading: false, authenticated: true, ...jwt }),
          ]).pipe(
            tap(() => {
              if (payload.redirect) {
                this.router.navigate(["/dashboard/active-orders"]);
              }
            })
          );
        })
      ),
    { dispatch: false }
  );
}
