import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { Report, ReportsState } from "../../entities";

export enum ReportsAction {
  ClearAll = "[REPORTS] Clear all reports",
  SetReportsUi = "[REPORTS] UI Reports",
  FetchReports = "[REPORTS] Fetch reports",
  SetReports = "[REPORTS] Set reports",
  DeleteReport = "[REPORTS] Delete report",
}

export const clearAll = createAction(ReportsAction.ClearAll);
export const setReportsUi = createAction(ReportsAction.SetReportsUi, props<{ ui: Array<AdapterUi<ReportsState["ui"]>> }>());
export const setReports = createAction(ReportsAction.SetReports, props<{ reports: Array<Report> }>());
export const fetchReports = createAction(ReportsAction.FetchReports, props<{ retailerId: number }>());
export const deleteReport = createAction(ReportsAction.DeleteReport, props<{ id: string; retailerId: number }>());
