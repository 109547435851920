import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import * as RappiSyncAction from "@modules/rappi-sync/store/actions/rappi-sync.action";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { RappiSyncState } from "../../entities/rappi-sync.entities";

const initialState: RappiSyncState = {
  ui: {
    isLoading: false,
    isGettingStoresFromCore: false,
    openMiTiendaLogin: false,
    showResult: false,
  },
  miTiendaToken: undefined,
  partnerPortalStores: [],
  miTiendaStores: [],
  selectedStores: [],
  storesOrigin: undefined,
  unsuccessfulStores: [],
  successfulStores: [],
};

const rappiSyncReduce = createReducer(
  initialState,
  on(RappiSyncAction.clearAll, () => initialState),
  on(RappiSyncAction.setUi, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(RappiSyncAction.setStoreOrigin, (state, { origin }) => ({ ...state, storesOrigin: origin })),
  on(RappiSyncAction.setUnsuccessfulStores, (state, { stores }) => ({ ...state, unsuccessfulStores: stores })),
  on(RappiSyncAction.setSuccessfulStores, (state, { stores }) => ({ ...state, successfulStores: stores })),
  on(RappiSyncAction.setStoresFromPartnerPortal, (state, { stores }) => ({
    ...state,
    partnerPortalStores: stores,
  })),
  on(RappiSyncAction.setStoresFromMiTienda, (state, { stores, token }) => ({
    ...state,
    miTiendaToken: token,
    miTiendaStores: stores,
  }))
);

export function RappiSyncReducer(state: RappiSyncState, action: Action) {
  return rappiSyncReduce(state, action);
}

export const getRappiSync = createFeatureSelector<RappiSyncState>("rappiSync");

export const getUi = createSelector(getRappiSync, ({ ui }) => ui);
export const getPartnerPortalStores = createSelector(getRappiSync, ({ partnerPortalStores }) => partnerPortalStores);
export const getMiTiendaStores = createSelector(getRappiSync, ({ miTiendaStores }) => miTiendaStores);
export const getMiTiendaToken = createSelector(getRappiSync, ({ miTiendaToken }) => miTiendaToken);
export const getStoresOrigin = createSelector(getRappiSync, ({ storesOrigin }) => storesOrigin);
export const getUnsuccessfulStores = createSelector(getRappiSync, ({ unsuccessfulStores }) => unsuccessfulStores);
export const getSuccessfulStores = createSelector(getRappiSync, ({ successfulStores }) => successfulStores);
