import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { translateCheckinAttributes } from "@app/modules/hovering-checkin/utils";
import { PP_QUERY_LIMIT } from "@app/modules/picking-points/services/picking-points.service";
import { currentRetailerStorageKey, localStorageKey, retailersStorageKey, userSessionStorageKey } from "@core/constants";
import { BasicRetailer, CheckIn, GlobalState, JwtSchema, Retailer, RetailerOrigin } from "@core/entities";
import { GlobalService } from "@core/services/global.service";
import { PopUpNotificationService } from "@core/services/popup-notification.service";
import * as GlobalAction from "@core/store/actions/global.action";
import { serializePickupPoints } from "@core/utils";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { StorageMap } from "@ngx-pwa/local-storage";
import { isAfter, isBefore } from "date-fns";
import { combineLatest, forkJoin, of } from "rxjs";
import { catchError, expand, map, mergeMap, reduce, switchMap, takeWhile, tap } from "rxjs/operators";
import { environment as ENV, environment } from "src/environments/environment";

@Injectable()
export class GlobalEffect {
  constructor(
    private actions$: Actions,
    private globalService: GlobalService,
    private popupService: PopUpNotificationService,
    private storage: StorageMap,
    private router: Router
  ) {}

  fetchComms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchComms),
      mergeMap(({ retailer }) =>
        this.globalService.fetchComms().pipe(
          mergeMap((response: any) => {
            function isWithinDateRange(s?: string, e?: string) {
              if (!s || !e) return true;
              const start = new Date(s);
              const end = new Date(e);
              const today = new Date();
              return isAfter(today, start) && isBefore(today, end);
            }
            function belongsToRetailerOrigin(e?: RetailerOrigin[]) {
              return e.includes(retailer?.origin) || (e ?? []).length === 0;
            }
            const mapped = response.map(r => ({ ...r.value, id: r.id }));
            const header = mapped.find(a => {
              return (
                a.type === "HEADER" &&
                belongsToRetailerOrigin(a.metaData.retailerOrigin ?? []) &&
                isWithinDateRange(a.metaData.start_date, a.metaData.end_date)
              );
            });
            let notifications = mapped.filter(a => {
              return (
                a.type === "NOTIFICATION" &&
                belongsToRetailerOrigin(a.metaData.retailerOrigin ?? []) &&
                isWithinDateRange(a.metaData.start_date, a.metaData.end_date)
              );
            });
            let announcements = mapped.filter(a => {
              return (
                a.type === "ANNOUNCEMENT" &&
                belongsToRetailerOrigin(a.metaData.retailerOrigin ?? []) &&
                isWithinDateRange(a.metaData.start_date, a.metaData.end_date)
              );
            });
            if (header) {
              header.title = header.title[environment.lang];
              header.subtitle = header.subtitle[environment.lang];
            }
            if (notifications) {
              notifications = notifications.map(n => ({ ...n, title: n.title[environment.lang], subtitle: n.subtitle[environment.lang] }));
            }
            if (announcements) {
              announcements = announcements.map(n => ({
                ...n,
                title: n.title ? n.title[environment.lang] : "",
                subtitle: n.subtitle ? n.subtitle[environment.lang] : "",
                info: n.info.map(i => ({
                  image: i.image ? i.image[environment.lang] : "",
                  description: i.description ? i.description[environment.lang] : "",
                })),
              }));
            }
            const comms: GlobalState["comms"] = {
              header,
              announcements,
              notifications,
            };
            return [GlobalAction.setComms({ payload: comms })];
          }),
          catchError((httpError: HttpErrorResponse) => {
            const { status, error } = httpError;
            console.error({ error, status, httpError });
            this.popupService.error(error);
            return of(GlobalAction.Empty());
          })
        )
      )
    )
  );

  getPickupPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchAllCheckin),
      mergeMap(() =>
        this.globalService.getAllCheckinCodes().pipe(
          mergeMap((response: any) => {
            const headerIds = Object.keys(response[0]).map(k => translateCheckinAttributes(k));
            const headers = Object.keys(response[0]);
            const parseResponse = response.map(r => ({
              ...r,
              checkin_code_url: `https://cargo.rappi.com.${ENV.code.toLowerCase()}/${r.checkin_code_url}`,
            }));
            this.globalService.downloadCSV(parseResponse, headerIds, headers, `checkin_${new Date().toISOString().split("T")[0]}`);
            return [];
          }),
          catchError((httpError: HttpErrorResponse) => {
            const { status, error } = httpError;
            console.error({ error, status });
            this.popupService.error(error);
            return of(GlobalAction.Empty());
          })
        )
      )
    )
  );

  recursivePickingPointFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchPickingPoints),
      mergeMap(({ ascendingName, activeOnly, retailerId }) => {
        return of({
          insufficientAmount: false,
          offset: 0,
          pp: [],
          retailerId,
        }).pipe(
          expand((data: any) => {
            const x = this.storage.get(retailersStorageKey).pipe(
              map((available: BasicRetailer[]) => {
                return available.map(a => a.id);
              })
            );
            return x.pipe(
              mergeMap(p => {
                return this.globalService
                  .getPickupPoints(
                    PP_QUERY_LIMIT,
                    data.offset,
                    ascendingName,
                    data.retailerId ? [data.retailerId] : (p as number[]),
                    activeOnly
                  )
                  .pipe(
                    map(newData => ({
                      pp: [...data.pp, ...newData],
                      insufficientAmount: newData.length < PP_QUERY_LIMIT,
                      offset: data.offset + newData.length,
                    }))
                  );
              })
            );
          }),
          takeWhile(data => !(data.insufficientAmount === true), true),
          map(data => data.pp),
          reduce((_acc, items) => items),
          mergeMap((res: any) => {
            if (res.length > 0) {
              return [
                GlobalAction.setSerializedPickingPoints({ payload: serializePickupPoints(res) }),
                GlobalAction.setPickingPoints({ payload: res }),
              ];
            }
          })
        );
      })
    )
  );

  gCheckin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchCheckin),
      mergeMap(p =>
        this.globalService.getCheckinCode(p.pickingPointId).pipe(
          mergeMap((checkin: CheckIn) => {
            return [
              GlobalAction.setCheckin({ checkin }),
              GlobalAction.uiGlobal({
                ui: [
                  { name: "loadingCheckin", value: false },
                  { name: "failedCheckin", value: false },
                ],
              }),
            ];
          }),
          catchError((httpError: HttpErrorResponse) => {
            console.error("Checkin Error", { httpError });
            const { error } = httpError;
            this.popupService.error(error);
            return of(
              GlobalAction.uiGlobal({
                ui: [
                  { name: "failedCheckin", value: true },
                  { name: "loadingCheckin", value: false },
                ],
              })
            );
          })
        )
      )
    )
  );

  getCurrentRetailer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.getCurrentRetailer),
      mergeMap(({ retailerId }) =>
        this.globalService.getCurrentRetailer(retailerId).pipe(
          mergeMap(retailer => {
            return [
              GlobalAction.uiGlobal({ ui: [{ name: "loadingRetailer", value: false }] }),
              GlobalAction.setCurrentRetailer({ retailer }),
            ];
          }),
          catchError((httpError: HttpErrorResponse) => {
            console.error({ httpError });
            const { error } = httpError;
            this.popupService.error(error);
            return of(GlobalAction.Empty());
          })
        )
      )
    )
  );

  refreshCurrentRetailer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.refreshCurrentRetailer),
      mergeMap(() => this.storage.get(currentRetailerStorageKey)),
      mergeMap((d: Retailer) => {
        return this.globalService.getCurrentRetailer(d.id).pipe(
          mergeMap(retailer => {
            return [
              GlobalAction.uiGlobal({ ui: [{ name: "loadingRetailer", value: false }] }),
              GlobalAction.setCurrentRetailer({ retailer }),
            ];
          }),
          catchError((httpError: HttpErrorResponse) => {
            console.error({ httpError });
            const { error } = httpError;
            this.popupService.error(error);
            return of(GlobalAction.Empty());
          })
        );
      })
    )
  );

  setCurrentRetailer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalAction.setCurrentRetailer),
        mergeMap(retailer => {
          return this.storage.set(currentRetailerStorageKey, { ...retailer.retailer, id: Number(retailer.retailer.id) });
        })
      ),
    { dispatch: false }
  );

  setRetailers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.setRetailers),
      mergeMap(({ retailers }) => {
        return combineLatest([
          this.storage.get(currentRetailerStorageKey),
          this.storage.get(localStorageKey),
          this.storage.get(userSessionStorageKey),
          this.storage.set(retailersStorageKey, retailers),
        ]).pipe(
          mergeMap(([current, auth, userSessionId, _]: [Retailer | undefined, JwtSchema, number, any]) => {
            if (!current || auth.id !== userSessionId || retailers.length === 1) {
              return [GlobalAction.getCurrentRetailer({ retailerId: retailers[0].id! })];
            } else {
              return [GlobalAction.getCurrentRetailer({ retailerId: current.id! })];
            }
          })
        );
      })
    )
  );

  setPickupPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.setSerializedPickingPoints),
      map(() => GlobalAction.uiGlobal({ ui: [{ name: "loadPickingPoints", value: false }] }))
    )
  );

  fetchInitialData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchInitialData),
      switchMap(() => {
        return forkJoin({
          orders: this.globalService.validateExistanceOfOrders(),
          pickingPoints: this.globalService.validateExistanceOfPickingPoints(),
        });
      }),
      mergeMap(({ orders, pickingPoints }) => {
        return [GlobalAction.setInitialData({ hasOrders: (orders ?? []).length > 0, hasPickingPoints: (pickingPoints ?? []).length > 0 })];
      }),
      catchError(() => {
        return [GlobalAction.uiGlobal({ ui: [{ name: "loadingOnboarding", value: false }] })];
      })
    )
  );

  fetchIsCashless$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.fetchIsCashless),
      mergeMap(({ retailerId }) =>
        this.globalService.validateCashlessness(retailerId).pipe(
          mergeMap(r => {
            return [GlobalAction.setSettlementAttribute({ settlement: { isCashless: (r?.billing_periods ?? []).length > 0 } })];
          }),
          catchError(() => {
            return [GlobalAction.setSettlementAttribute({ settlement: { isCashless: false } })];
          })
        )
      )
    )
  );

  handlePossibleReroute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalAction.setInitialData),
        tap(e => {
          if (!e.hasOrders && !e.hasPickingPoints) {
            this.router.navigate(["/", "dashboard", "welcome"]);
          }
        })
      ),
    { dispatch: false }
  );
}
