import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { AnalyticsState, PossibleRange } from "../../entities";
import * as AnalyticsAction from "../actions/analytics.action";

const initialState: AnalyticsState = {
  ui: {
    loading: false,
  },
  selectedPickingPoint: undefined,
  selectedRange: PossibleRange.WEEK,
  stats: [],
  forecast: undefined,
  histograms: [],
};

const reportsReduce = createReducer(
  initialState,
  on(AnalyticsAction.clearAll, () => initialState),
  on(AnalyticsAction.setAnalyticsUi, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(AnalyticsAction.setData, (state, { stats, histograms }) => ({ ...state, stats, histograms })),
  on(AnalyticsAction.setForecast, (state, forecast) => ({ ...state, forecast })),
  on(AnalyticsAction.modifyFilters, (state, { pickingPointId: selectedPickingPoint, range: selectedRange }) => ({
    ...state,
    selectedPickingPoint,
    selectedRange,
  }))
);

export function AnalyticsReducer(state: AnalyticsState, action: Action) {
  return reportsReduce(state, action);
}

export const getAnalyticsState = createFeatureSelector<AnalyticsState>("analytics");
export const getAnalyticsUi = createSelector(getAnalyticsState, ({ui}) => ui);
export const getAnalyticsStats = createSelector(getAnalyticsState, ({ stats }) => stats);
export const getAnalyticsHistograms = createSelector(getAnalyticsState, ({ histograms }) => histograms);
export const getSelectedPickingPoint = createSelector(getAnalyticsState, ({ selectedPickingPoint }) => selectedPickingPoint);
export const getSelectedRange = createSelector(getAnalyticsState, ({ selectedRange }) => selectedRange);
export const getForecast = createSelector(getAnalyticsState, ({ forecast }) => forecast);
