import { PickingPoint, SerializedPickingPoint } from "@core/entities";

export * from "./date-parser.util";
export * from "./mutator.util";

export function calculatePercentage(base: number, offer: number) {
  const calc = ((base - offer) / base) * 100;
  const valuate = parseInt(calc.toFixed(0), 0) <= 0 ? calc.toFixed(1) : calc.toFixed(0);
  return `OFF ${valuate}%`;
}

// create a new object with only properties that are in a given list of property names.
export const pick = (sourceObj, pickedKeys) => {
  const newObj = {};
  Object.keys(sourceObj)
    .filter(sourceKey => {
      return pickedKeys.some(key => {
        return sourceKey === key;
      });
    })
    .forEach(key => {
      newObj[key] = sourceObj[key];
    });
  return newObj;
};

export const setSavedState = (state: any, storageKey: string) => {
  localStorage.setItem(storageKey, JSON.stringify(state));
};

export const getSavedState = <T>(storageKey: string): T => {
  return JSON.parse(localStorage.getItem(storageKey));
};

export function addOrReplace<T>(array, obj, prop): T {
  const index = array.findIndex(e => e[prop] === obj[prop]);

  if (index === -1) {
    array.push(obj);
  } else {
    array[index] = obj;
  }
  return array;
}

export function serializePickupPoints(pickupPoints: PickingPoint[]): SerializedPickingPoint[] {
  return pickupPoints.map(pick => ({ key: pick, label: pick.name }));
}

export function removeNullOrEmpty(data: any): object {
  const strData = JSON.stringify(data, (k, obj) => {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || (obj[propName] === "" && !(obj[propName] instanceof Object))) {
        delete obj[propName];
      } else if (obj[propName] instanceof Object) {
        const newValue = removeNullOrEmpty(obj[propName]);
        if (!Object.keys(newValue).length) {
          delete obj[propName];
        }
      }
    }
    return obj;
  });
  return JSON.parse(strData);
}
