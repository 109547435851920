import { PickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { PickingPointsState } from "../../entities/picking-points.entities";

export enum PickingPointAction {
  ClearAll = "[PICKING-POINTS] Clear all picking-points",
  UiPickingPoints = "[PICKING-POINTS] UI picking-points",
  Empty = "[PICKING-POINTS] Empty",
  FetchPickingPoints = "[PICKING-POINTS] Fetch Picking points",
  SetPickingPoints = "[PICKING-POINTS] Set Picking points",
  UpdatePickingPointStatus = "[PICKING-POINTS] Update Picking Point",
  FetchPickingPointId = "[PICKING-POINTS] Fetch Picking point by Id",
  DeletePickingPoint = "[PICKING-POINTS] Delete PP",
  DeletePickingPointRequest = "[PICKING-POINTS] Delete PP Request",
}

export const clearAll = createAction(PickingPointAction.ClearAll);
export const uiPickingPoints = createAction(
  PickingPointAction.UiPickingPoints,
  props<{ ui: Array<AdapterUi<PickingPointsState["ui"]>> }>()
);
export const Empty = createAction(PickingPointAction.Empty);

export const updatePickingPointStatus = createAction(
  PickingPointAction.UpdatePickingPointStatus,
  props<{ payload: Partial<PickingPoint> }>()
);
export const fetchPickingPoints = createAction(PickingPointAction.FetchPickingPoints, props<{ search?: string }>());
export const setPickingPoints = createAction(PickingPointAction.SetPickingPoints, props<{ payload: PickingPoint[] }>());
export const deletePickingPoint = createAction(PickingPointAction.DeletePickingPoint, props<{ id: number }>());
export const deletePickingPointRequest = createAction(PickingPointAction.DeletePickingPointRequest, props<{ id: number }>());
