import { ActiveOrderListResponse } from "@app/modules/orders/entities/orders.entities";
import { Tab } from "@app/shared/components/tabs/entities";
import { CONTROL_TOWER_SECTIONS, CountByPickingPoint, FilterCard, PossibleControlTowerActions } from "../entities";

export const CONTROL_TOWER_SUBSECTIONS: { [s: string]: { [x: string]: string } } = {
  [CONTROL_TOWER_SECTIONS.LIVE]: {
    ASSIGNMENT: "ASSIGNMENT",
    EN_ROUTE_STORE: "EN_ROUTE_STORE",
    WAITING_TIME: "WAITING_TIME",
    CLOSE_ORDER: "CLOSE_ORDER",
  },
  [CONTROL_TOWER_SECTIONS.RETURNS]: {
    RETURNS: "RETURNS",
  },
  [CONTROL_TOWER_SECTIONS.PICKING_POINT]: {
    PICKING_POINTS: "PICKING_POINTS",
  },
};

export const CONTROL_TOWER_COLUMNS_BY_SUBTAB: {
  [X: string]: { [s: string]: (keyof ActiveOrderListResponse | keyof CountByPickingPoint)[] };
} = {
  [CONTROL_TOWER_SECTIONS.LIVE]: {
    [CONTROL_TOWER_SUBSECTIONS.LIVE.ASSIGNMENT]: [
      "created_at",
      "destination_first_name",
      "destination_last_name",
      "destination_address",
      "address",
      "picking_point.name",
      "external_order_id",
      "rappi_order_id",
    ],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.EN_ROUTE_STORE]: [
      "created_at",
      "destination_first_name",
      "destination_last_name",
      "destination_address",
      "address",
      "picking_point.name",
      "external_order_id",
      "rappi_order_id",
    ],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.WAITING_TIME]: [
      "created_at",
      "address",
      "picking_point.name",
      "external_order_id",
      "handshake_code",
      "rappi_order_id",
    ],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.CLOSE_ORDER]: [
      "created_at",
      "destination_first_name",
      "destination_last_name",
      "destination_address",
      "handshake_code",
      "external_order_id",
      "rappi_order_id",
    ],
  },
  [CONTROL_TOWER_SECTIONS.RETURNS]: {
    [CONTROL_TOWER_SUBSECTIONS.RETURNS.RETURNS]: [
      "created_at",
      "destination_first_name",
      "destination_last_name",
      "destination_address",
      "address",
      "picking_point.name",
      "external_order_id",
      "rappi_order_id",
    ],
  },
  [CONTROL_TOWER_SECTIONS.PICKING_POINT]: {
    [CONTROL_TOWER_SUBSECTIONS.PICKING_POINT.PICKING_POINTS]: [
      "picking_point_id",
      "in_progress",
      "on_the_route",
      "arrive",
      "in_store",
      "created",
      "finding_courier",
      "failed_return",
      "failed_delivery",
      "return_in_store",
    ],
  },
};

export const CONTROL_TOWER_TABLE_ACTION_BY_SUBTAB: {
  [X: string]: { [s: string]: PossibleControlTowerActions[] };
} = {
  [CONTROL_TOWER_SECTIONS.LIVE]: {
    [CONTROL_TOWER_SUBSECTIONS.LIVE.ASSIGNMENT]: [PossibleControlTowerActions.ADD_TIP],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.EN_ROUTE_STORE]: [PossibleControlTowerActions.CONTACT_SUPPORT],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.WAITING_TIME]: [PossibleControlTowerActions.GO_TO_TRACKING_LINK],
    [CONTROL_TOWER_SUBSECTIONS.LIVE.CLOSE_ORDER]: [PossibleControlTowerActions.CONTACT_SUPPORT],
  },
  [CONTROL_TOWER_SECTIONS.RETURNS]: {
    [CONTROL_TOWER_SUBSECTIONS.RETURNS.RETURNS]: [PossibleControlTowerActions.CONTACT_SUPPORT],
  },
  [CONTROL_TOWER_SECTIONS.PICKING_POINT]: {
    [CONTROL_TOWER_SUBSECTIONS.PICKING_POINT.PICKING_POINTS]: [],
  },
};

export const ACTIONS_ICON_URL: { [x: string]: string } = {
  [PossibleControlTowerActions.ADD_TIP]: "/assets/icons/svg/coin.svg",
  [PossibleControlTowerActions.CONTACT_SUPPORT]: "/assets/icons/svg/customer-service.svg",
  [PossibleControlTowerActions.GO_TO_TRACKING_LINK]: "/assets/icons/svg/link-rotate.svg",
};

export const CONTROL_TOWER_ORDER_IDS_BY_SUBTAB: { [x: string]: { [s: string]: number[] } } = {
  [CONTROL_TOWER_SECTIONS.LIVE]: {
    [CONTROL_TOWER_SUBSECTIONS.LIVE["ASSIGNMENT"]]: [1, 20],
    [CONTROL_TOWER_SUBSECTIONS.LIVE["EN_ROUTE_STORE"]]: [8],
    [CONTROL_TOWER_SUBSECTIONS.LIVE["WAITING_TIME"]]: [10],
    [CONTROL_TOWER_SUBSECTIONS.LIVE["CLOSE_ORDER"]]: [12],
  },
  [CONTROL_TOWER_SECTIONS.RETURNS]: {
    [CONTROL_TOWER_SUBSECTIONS.RETURNS["RETURNS"]]: [21, 17, 27],
  },
};

export const CONTROL_TOWER_ORDER_IDS_BY_TAB: { [x: string]: number[] } = {
  [CONTROL_TOWER_SECTIONS.LIVE]: [
    ...CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE][CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["ASSIGNMENT"]],
    ...CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE][
      CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["EN_ROUTE_STORE"]
    ],
    ...CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE][
      CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["WAITING_TIME"]
    ],
    ...CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE][
      CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["CLOSE_ORDER"]
    ],
  ],
  [CONTROL_TOWER_SECTIONS.RETURNS]: [
    ...CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.RETURNS][
      CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.RETURNS]["RETURNS"]
    ],
  ],
};

export const FilterCardOptions: { [x: string]: FilterCard[] } = {
  PICKING_POINT: [],
  RETURNS: [
    {
      id: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.RETURNS]["RETURNS"],
      orderIds: CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.RETURNS]["RETURNS"],
      label: ["CONTROL_TOWER.FILTERS.RETURNS.LABEL_1", "CONTROL_TOWER.FILTERS.RETURNS.LABEL_2"],
      description: "CONTROL_TOWER.FILTERS.RETURNS.DESCRIPTION",
      mainValue: 0,
      mainValueUnit: "CONTROL_TOWER.FILTERS.RETURNS.MAIN_VALUE_UNIT",
    },
  ],
  LIVE: [
    {
      id: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["ASSIGNMENT"],
      orderIds: CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE]["ASSIGNMENT"],
      label: ["CONTROL_TOWER.FILTERS.ASSIGNMENT.LABEL_1", "CONTROL_TOWER.FILTERS.ASSIGNMENT.LABEL_2"],
      description: "CONTROL_TOWER.FILTERS.ASSIGNMENT.DESCRIPTION",
      mainValue: 0,
      mainValueUnit: "CONTROL_TOWER.FILTERS.ASSIGNMENT.MAIN_VALUE_UNIT",
    },
    {
      id: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["EN_ROUTE_STORE"],
      orderIds: CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE]["EN_ROUTE_STORE"],
      label: ["CONTROL_TOWER.FILTERS.EN_ROUTE_STORE.LABEL_1", "CONTROL_TOWER.FILTERS.EN_ROUTE_STORE.LABEL_2"],
      description: "CONTROL_TOWER.FILTERS.EN_ROUTE_STORE.DESCRIPTION",
      mainValue: 0,
      mainValueUnit: "CONTROL_TOWER.FILTERS.EN_ROUTE_STORE.MAIN_VALUE_UNIT",
    },
    {
      id: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["WAITING_TIME"],
      orderIds: CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE]["WAITING_TIME"],
      label: ["CONTROL_TOWER.FILTERS.WAITING_TIME.LABEL_1", "CONTROL_TOWER.FILTERS.WAITING_TIME.LABEL_2"],
      description: "CONTROL_TOWER.FILTERS.WAITING_TIME.DESCRIPTION",
      mainValue: 0,
      mainValueUnit: "CONTROL_TOWER.FILTERS.WAITING_TIME.MAIN_VALUE_UNIT",
    },
    {
      id: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["CLOSE_ORDER"],
      orderIds: CONTROL_TOWER_ORDER_IDS_BY_SUBTAB[CONTROL_TOWER_SECTIONS.LIVE]["CLOSE_ORDER"],
      label: ["CONTROL_TOWER.FILTERS.CLOSE_ORDER.LABEL_1", "CONTROL_TOWER.FILTERS.CLOSE_ORDER.LABEL_2"],
      description: "CONTROL_TOWER.FILTERS.CLOSE_ORDER.DESCRIPTION",
      mainValue: 0,
      mainValueUnit: "CONTROL_TOWER.FILTERS.CLOSE_ORDER.MAIN_VALUE_UNIT",
    },
  ],
};

export const ActionsBySubTab: { [x: string]: { [k: string]: PossibleControlTowerActions[] } } = {
  [CONTROL_TOWER_SECTIONS.LIVE]: {
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["ASSIGNMENT"]]: [
      PossibleControlTowerActions.ADD_TIP,
      PossibleControlTowerActions.CONTACT_USER,
      PossibleControlTowerActions.CONTACT_SUPPORT,
      PossibleControlTowerActions.GO_TO_TRACKING_LINK,
      PossibleControlTowerActions.CANCEL_ORDER,
    ],
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["EN_ROUTE_STORE"]]: [
      PossibleControlTowerActions.CONTACT_RT,
      PossibleControlTowerActions.CONTACT_SUPPORT,
      PossibleControlTowerActions.GO_TO_TRACKING_LINK,
      PossibleControlTowerActions.CANCEL_ORDER,
    ],
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["WAITING_TIME"]]: [
      PossibleControlTowerActions.CONTACT_RT,
      PossibleControlTowerActions.CONTACT_SUPPORT,
      PossibleControlTowerActions.GO_TO_TRACKING_LINK,
      PossibleControlTowerActions.CANCEL_ORDER,
    ],
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["CLOSE_ORDER"]]: [
      PossibleControlTowerActions.CONTACT_RT,
      PossibleControlTowerActions.CONTACT_USER,
      PossibleControlTowerActions.CONTACT_SUPPORT,
      PossibleControlTowerActions.GO_TO_TRACKING_LINK,
      PossibleControlTowerActions.CANCEL_ORDER,
    ],
  },
  [CONTROL_TOWER_SECTIONS.RETURNS]: {
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.RETURNS]["RETURNS"]]: [
      PossibleControlTowerActions.REMOVE_DEBT,
      PossibleControlTowerActions.GO_TO_TRACKING_LINK,
      PossibleControlTowerActions.CONTACT_SUPPORT,
      PossibleControlTowerActions.CONTACT_RT,
    ],
  },
  [CONTROL_TOWER_SECTIONS.PICKING_POINT]: {
    [CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.PICKING_POINT]["ASSIGNMENT"]]: [],
  },
};

export const ControlTowerTabOptions: Tab[] = [
  {
    label: "CONTROL_TOWER.TAB_OPTIONS.LIVE",
    id: CONTROL_TOWER_SECTIONS.LIVE,
    callback: () => {},
  },
  {
    label: "CONTROL_TOWER.TAB_OPTIONS.RETURNS",
    id: CONTROL_TOWER_SECTIONS.RETURNS,
    callback: () => {},
  },
  {
    label: "CONTROL_TOWER.TAB_OPTIONS.PICKING_POINT",
    id: CONTROL_TOWER_SECTIONS.PICKING_POINT,
    callback: () => {},
  },
];
