import { IError } from "@core/entities";
import { createAction, props } from "@ngrx/store";

export enum ErrorsAction {
  ClearAll = "[ERRORS] Clear all errors",
  AddOneError = "[ERRORS] Add error",
  RemoveOneError = "[ERRORS] Remove error",
  RemoveManyErrors = "[ERRORS] Remove many errors",
  AddManyErrors = "[ERRORS] Add many errors",
}

export const clearAll = createAction(ErrorsAction.ClearAll);
export const addOneError = createAction(ErrorsAction.AddOneError, props<{ payload: IError }>());
export const removeOneError = createAction(ErrorsAction.RemoveOneError, props<{ id: string }>());
export const addManyErrors = createAction(ErrorsAction.AddManyErrors, props<{ errors: IError[] }>());
export const removeManyErrors = createAction(ErrorsAction.RemoveManyErrors, props<{ ids: string[] }>());
