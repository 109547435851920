import { Retailer } from "@core/entities";
import { createAction, props } from "@ngrx/store";
import { DevPortalReducerState } from "../../entities";

export enum DevPortalAction {
  ClearAll = "[DEV PORTAL] Clear all create order",
  Empty = "[DEV PORTAL] Empty",
  FetchOrders = "[DEV PORTAL] Fetch Orders",
  FetchLogs = "[DEV PORTAL] Fetch Logs",
  SaveWebhookConfig = "[DEV PORTAL] Save Webhook Config",
  SetLogs = "[DEV PORTAL] Set Logs",
  SetOrders = "[DEV PORTAL] Set Orders",
  SetSelectedOrder = "[DEV PORTAL] Set Order",
  AdvanceOrder = "[DEV PORTAL] Advance Order",
  UpdateRetailer = "[DEV PORTAL] Update Retailer",
  SetUi = "[DEV PORTAL] SetUi",
}

export const clearAll = createAction(DevPortalAction.ClearAll);
export const empty = createAction(DevPortalAction.Empty);
export const fetchOrders = createAction(DevPortalAction.FetchOrders, props<{ searchInput: string }>());
export const setOrders = createAction(DevPortalAction.SetOrders, props<{ orders: any[] }>());
export const setSelectedOrder = createAction(DevPortalAction.SetSelectedOrder, props<{ selectedOrder: any }>());
export const setLogs = createAction(DevPortalAction.SetLogs, props<{ logs: any[] }>());
export const advanceOrder = createAction(DevPortalAction.AdvanceOrder, props<{ nextStep: string; orderId: number; favour: boolean }>());
export const updateRetailer = createAction(DevPortalAction.UpdateRetailer, props<{ retailer: Partial<Retailer> }>());
export const fetchLogs = createAction(DevPortalAction.FetchLogs, props<{ selectedOrder: any }>());
export const setUi = createAction(DevPortalAction.SetUi, props<{ ui: Partial<DevPortalReducerState["ui"]> }>());
