import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { PP_QUERY_LIMIT } from "@app/modules/picking-points/services/picking-points.service";
import { environment as env } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(private http: HttpClient) {}

  public getPickupPoints(
    limit: number = PP_QUERY_LIMIT,
    offset: number = 0,
    ascendingName: boolean,
    retailerIds: number[],
    justActive?: boolean
  ): Observable<any> {
    const body = {
      limit,
      offset,
      orderby: ascendingName ? "name" : "",
      retailerIds,
      justActive,
    };

    return this.http.post<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}picking-point/list`, body);
  }

  public getCheckinCode(pickingPointId: number): Observable<any> {
    return this.http.get<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}picking-point/checkin`, {
      params: { p: btoa(JSON.stringify({ picking_point_id: pickingPointId })) },
    });
  }

  public getAllCheckinCodes(): Observable<any> {
    return this.http.get<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}picking-point/list/checkin-code`);
  }

  public getCurrentRetailer(retailerId: number): Observable<any> {
    if (retailerId) {
      return this.http.get<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}retailer/${retailerId}`);
    }
  }

  public fetchComms(): Observable<any> {
    return this.http.get<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}user/configs/list/ANNOUNCEMENT`);
  }

  public downloadCSV(data, headers, headerIds, filename = "data") {
    const csvData = this.convertToCSV(data, headerIds, headers);
    const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  private convertToCSV(objArray: any[], headerIds: string[], headerList: string[]) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";
    for (const index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in headerIds) {
        const head = headerIds[index];

        line += (line === "" ? "" : ",") + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }

  public validateExistanceOfPickingPoints(): Observable<any> {
    const body = {
      limit: 1,
      offset: 0,
    };

    return this.http.post<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}picking-point/list`, body);
  }

  public validateExistanceOfOrders(): Observable<any> {
    const queryParams = [];
    queryParams.push(`?limit=${1}`, `offset=${0}`);

    return this.http.get<any>(`${env.gateway.base_url}${env.gateway.gateway_prefix}order/list${queryParams.join("&")}`);
  }

  public validateCashlessness(retailerId: number): Observable<any> {
    const queryParams = [];
    queryParams.push(`?limit=${1}`, `offset=${0}`);

    return this.http.get(`${env.gateway.base_url}${env.gateway.settlement_prefix}billing-periods/`, {
      headers: { "x-retailer-id": retailerId + "" },
    });
  }
}
