import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AuthFacade } from "@app/modules/auth/facade/auth.facade";

@Injectable()
export class UnAuthenticatedGuard implements CanActivate {
  constructor(protected router: Router, protected authModel: AuthFacade) {}

  canActivate(): Observable<boolean> {
    return this.authModel.getAuth().pipe(
      tap(authenticated => {
        if (authenticated) {
          this.router.navigate(["/dashboard/active-orders"]);
        }
      }),
      map(authenticated => !authenticated)
    );
  }
}
