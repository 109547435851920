import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateService } from "@ngx-translate/core";

import { HttpConfigInterceptor } from "@core/interceptors/main.interceptor";
import { GlobalService } from "@core/services/global.service";
import { environment } from "@environments/environment";
import { PopUpNotificationService } from "./services/popup-notification.service";
import { StoreUtil } from "./store/utils/store.util";

// Effects
import { CargoAuthEffects } from "@app/modules/auth/store/effects/auth.effect";
import { GlobalEffect } from "@core/store/effects/global.effect";

// Modules
import { TranslateLoaderUtil } from "@core/translate/translate-loader.util";
import { setTranslate } from "@core/translate/translate.util";
import { GuardsModule } from "./guards/guards.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GuardsModule,
    StoreUtil.globalStore(),
    TranslateLoaderUtil.initTranslate(),
    HttpClientModule,
    EffectsModule.forRoot([GlobalEffect, CargoAuthEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !environment.production }),
  ],
  providers: [
    PopUpNotificationService,
    GlobalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setTranslate,
      deps: [TranslateService],
      multi: true,
    },
  ],
})
export class CoreModule {}
