import { HttpClient } from "@angular/common/http";
import { ModuleWithProviders } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { createTranslateLoader } from "./translate.util";

export class TranslateLoaderUtil {
  public static initTranslate(): ModuleWithProviders<any> {
    return TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    });
  }
}
