import { EnvironmentType, I18N_VERSION } from "./environment.type";

export const environment: EnvironmentType = {
  placement: "prod",
  production: true,
  route_hash: false,
  timezone: "America/Lima",
  code: "PE",
  currency: "PEN",
  grammar: "es",
  lang: "es",
  phone_length: 9,
  max_phone_length: 15,
  max_tip: 200,
  country_code: "+51",
  gateway: {
    base_url: "https://services.rappi.pe",
    csv_prefix: "/api/cargo-api-gateway/csv/portal/",
    gateway_prefix: "/api/cargo-api-gateway/portal/",
    settlement_prefix: "/api/cargo-api-gateway/portal/settlement/",
  },
  logoUrl: "https://cargo-core-api.s3.us-west-2.amazonaws.com/pe/cargo-logos/",
  trackingUrl: "cargo-tracking.rappi.pe/",
  maps_api: {
    api_key: "AIzaSyAUVHuCaloj77G6ybqUghPO7rJAXB2qnbg",
  },
  coordsCenter: { lat: -12.04318, lng: -77.02824 },
  currencySymbol: "S/.",
  decimalPattern: /^[0-9]+([.]\d{0,1})?$/,
  decimalNumber: 1,
  country: {
    defaultLocale: "es-PE",
  },
  localeUrl: "https://cargo-core-api.s3.amazonaws.com/pe/cargo-logos/",
  auth0: {
    client_id: "hDcAOaY9heMYiAxYPvr5Ynt3ZeNybA43",
    client_secret: "OxnMGuC8OXHjYTgznaaKL2ItdOB4xdMj2jpTDbx6au00NQX47lBEdjgGXc6ia2m4",
    domain: "https://login.partners.rappi.com",
  },
  amplitude: {
    key: "032f00f0dfdad881e7391f28f35156f6",
  },
  OAuthGateway: "https://www.rappi.com.pe/login-legacy/signup",
  i18n_version: I18N_VERSION,
};
