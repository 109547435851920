import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export interface PopUpEntity {
  lnTitle?: string;
  lnContent?: string;
  lnOkText: string;
  lnCancelText: string;
}

type IError = {
  message?: string;
  field?: string;
  i18n_code?: string;
  internationalized_message?: string;
};

@Injectable({
  providedIn: "root",
})
export class PopUpNotificationService {
  constructor(private translate: TranslateService) {
    const element = document.createElement("div");
    element.setAttribute("id", "notification-container");
    document.body.appendChild(element);
  }

  public confirmPopup(values: PopUpEntity): Promise<any> {
    const element = `
      <div id="parent-confirm" class="kl-confirm">
        <div class="kl-wrapper-box"></div>
        <div class="kl-confirm-box">
          <div class="kl-confirm-content">
            <div class="kl-confirm-header">
              <h4 class="regular">${values?.lnTitle}</h4>
              <h5 class="mt-2 light">${values?.lnContent}</h5>
            </div>
            <div class="btn-group mt-20">
              <button class="kl-btn btn-secondary p-2" id="onclickok"><span>${values?.lnOkText}</span></button>
              <button class="kl-btn btn-outline-primary ml-3 p-2" id="onclickcancel"><span>${values?.lnCancelText}</span></button>
            </div>
          </div>
        </div>
      </div>
    `;
    document.body.insertAdjacentHTML("beforeend", element);
    return this.resolver({ success: "onclickok", cancel: "onclickcancel" });
  }

  public hasTranslation(key: string, language?: string): boolean {
    const translation: object | string = this.translate.translations[language || this.translate.currentLang];
    const value = key.split(".").reduce((t, k) => t[k] || {}, translation || {});
    return value !== undefined;
  }

  private resolver(element: { success: string; cancel: string }): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.handelActions(element.success, resolve);
      this.handelActions(element.cancel, reject);
    });
    return promise;
  }

  private handelActions(element, action) {
    document.getElementById(element).addEventListener("click", () => {
      action();
      const IdElement = document.getElementById("parent-confirm");
      IdElement.parentNode.removeChild(IdElement);
    });
  }

  private createNotification(message, status) {
    const idNotification = `notification-${Math.floor(Math.random() * 1000 + 1)}`;
    const parentElement = document.getElementById("notification-container");
    let icon = null;

    switch (status) {
      case "info": {
        icon = "iconf-info info";
        break;
      }
      case "error": {
        icon = "iconf-pending error";
        break;
      }
      case "success": {
        icon = "iconf-rectangle-3 success";
        break;
      }
      default: {
        icon = "iconf-gaming3x error";
        break;
      }
    }
    const element = `
      <div id="${idNotification}" class="kl-notification mt-10">
        <i  class="${icon}"></i>
        <span class="ml-10 light">${message}</span>
      </div>
    `;

    parentElement.insertAdjacentHTML("afterbegin", element);

    setTimeout(() => {
      const IdElement = document.getElementById(idNotification);
      IdElement.parentNode.removeChild(IdElement);
    }, 5000);
  }

  public error({ message, i18n_code, internationalized_message }: IError, translate: boolean = true) {
    let result_message = message;
    if (internationalized_message) {
      result_message = internationalized_message;
    } else if (translate && i18n_code) {
      const translateable = this.hasTranslation(`${i18n_code}`);
      if (translateable) {
        result_message = this.translate.instant(`${i18n_code.toUpperCase()}`);
      }
    }
    this.createNotification(result_message || this.translate.instant("ERRORS.OTHER"), "error");
  }

  public info({ message }) {
    this.createNotification(message, "info");
  }

  public success({ message }) {
    this.createNotification(message, "success");
  }
}
