import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { AuthState } from "@modules/auth/entities";
import * as actions from "@modules/auth/store/actions/login.action";

export const authInitialState: AuthState = {
  user: null,
  access_token: null,
  token_type: null,
  expires_in: 0,
  refresh_token: null,
  first_login: false,
  authenticated: false,
  application_user_id: null,
  loading: false,
  error: null,
  ui: {
    currentView: "loader",
    loadBtn: false,
    openResetPasswordModal: false,
  },
};

const createAuthReducer = createReducer(
  authInitialState,
  on(actions.clearAll, () => ({ ...authInitialState })),
  on(actions.uiAuth, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(actions.goLogin, state => {
    setTimeout(() => {
      window.location.href = "/auth/login";
    }, 1500);
    return state;
  }),
  on(actions.setAppUserId, (state, { payload }) => ({ ...state, application_user_id: payload }))
);

export function authReducer(state, action) {
  return createAuthReducer(state, action);
}

export const getAuthStore = createFeatureSelector<AuthState>("auth");
export const getUiAuth = createSelector(getAuthStore, ({ ui }) => ui);
export const getAppUserId = createSelector(getAuthStore, ({ application_user_id }) => application_user_id);
