
export enum PossibleRange {
  'WEEK' = 'ANALYTICS.FILTERS.PERIODS.WEEKS',
  'DAYS' = 'ANALYTICS.FILTERS.PERIODS.DAYS',
  'MONTHS' = 'ANALYTICS.FILTERS.PERIODS.MONTHS'
}

export interface AnalyticsState {
  ui: AnalyticsUi;
  selectedPickingPoint: number | undefined;
  selectedRange: PossibleRange;
  stats: AnalyticsStat[];
  histograms: AnalyticsHistogram[];
  forecast: Forecast | undefined;
}

export interface Forecast {
  expected: number,
  amountOrdersInPreviousMonth: number,
  amountOrdersUpToDate: number,
  totalOrdersLastMonth: number,
  lastDayWithData: string,
  expectedDelta: number
}

export interface AnalyticsUi {
  loading: boolean;
}

export interface AnalyticsStat {
  name: string;
  value: number;
  valueUnits?: string;
  weeklyDelta: number;
  weeklyDeltaLabel: string;
  weeklyDeltaUnits?: string;
  helperText?: string;
}

export interface GroupedStats {
  totalOrders: number,
  canceledOrders: number,
  cancelRate: number,
  pctOrdersOver60: number,
  pctOrdersUnder30: number,
  waitingTime: number,
  timeToClose: number,
  timeE2E: number,
  timeFull: number
}

export interface ComparableStat {
  now: AnalyticsStat,
  before: AnalyticsStat,
}

export interface AnalyticsHistogram {
  bars?: DataSet[],
  lines?: DataSet[],
  title: string,
  xLabels: string[],
  xLabel?: string,
  yLabel?: string,
  yMax: number,
  yMin?: number,
  yAxisUnits?: [string, string, true][] 
}

export interface AnalyticsPiechart {
  slices: Slice[],
  title: string
}

export interface Slice {
  amount: number;
  label: string;
}

export interface DataSet {
  label: string;
  dataset: number[];
  yAxis?: string;
}
