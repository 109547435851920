import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import * as PickingPointsAction from "@modules/picking-points/store/actions/picking-points.action";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { PickingPointsState } from "../../entities/picking-points.entities";

const initialState: PickingPointsState = {
  ui: {
    deleteSuccess: false,
    hasData: false,
    isForm: false,
    isLoading: true,
    isBatchUpdating: false,
    isModalDeleteShow: false,
    loaderBtn: false,
    succesfulImport: false,
  },
  picking_points: null,
  selected_pp: null,
};

const pickingPointsReduce = createReducer(
  initialState,
  on(PickingPointsAction.clearAll, () => initialState),
  on(PickingPointsAction.setPickingPoints, (state, { payload }) => ({
    ...state,
    picking_points: payload,
  })),
  on(PickingPointsAction.uiPickingPoints, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(PickingPointsAction.deletePickingPoint, (state, { id }) => ({
    ...state,
    picking_points: [...state.picking_points.filter(pickingPoint => id !== pickingPoint.id)],
  }))
  // on(PickingPointsAction.filterIncompleteStores, (state, { stores }) => {
  //   return {
  //     ...state,
  //     ui: {
  //       ...state.ui,
  //       isBatchUpdating: false,
  //     },
  //     incomplete_core_stores: {
  //       failedCreating: stores
  //         .filter(s => (state.incomplete_core_stores?.failedCreating ?? []).find(e => e.data.id === s.data.id))
  //         .map(s => ({ data: s.data, valid: { reasons: [s.validationError], valid: !!!s.validationError } })),
  //       failedFromCore: stores
  //         .filter(s => (state.incomplete_core_stores?.failedFromCore ?? []).find(e => e.data.id === s.data.id))
  //         .map(s => ({ data: s.data, valid: { reasons: [s.validationError], valid: !!!s.validationError } })),
  //       failedUpdating: stores
  //         .filter(s => (state.incomplete_core_stores?.failedUpdating ?? []).find(e => e.data.id === s.data.id))
  //         .map(s => ({ data: s.data, valid: { reasons: [s.validationError], valid: !!!s.validationError } })),
  //     },
  //   };
  // })
);

export function PickingPointsReducer(state: PickingPointsState, action: Action) {
  return pickingPointsReduce(state, action);
}

export const getPickingPoints = createFeatureSelector<PickingPointsState>("pickingPoints");
export const getUiPickingPoints = createSelector(getPickingPoints, ({ ui }) => ui);
// export const getCoreStores = createSelector(getPickingPoints, ({ core_stores }) => core_stores);
// export const getIncompleteCoreStores = createSelector(getPickingPoints, ({ incomplete_core_stores }) => incomplete_core_stores);
export const getAllPickingPoints = createSelector(getPickingPoints, ({ picking_points }) => picking_points);
export const getSelectedPickingPoint = createSelector(getPickingPoints, ({ selected_pp }) => selected_pp);
