import { Injectable } from "@angular/core";
import { Adapter, LoadScripts, StoreAdapter, Style } from "web-adapter-js";
interface ScriptsType {
  name: string;
  src: string;
}

interface StylesType {
  name: string;
  src: string;
  type: string;
}
@Injectable()
export class WebAdapterService {
  private initAdapter = new Adapter();
  private load = new LoadScripts();

  private stylesLoader = new Style();
  private adapterRoot = StoreAdapter.root;

  constructor() {}

  public loadStyles(styles: StylesType[]): void {
    this.stylesLoader.loadCdn([...styles]);
  }

  public dispatch<T>(data: T): void {
    this.adapterRoot.dispatch(data);
  }

  public initElements(scripts: ScriptsType[]): void {
    this.initAdapter.init();
    this.load.import([...scripts]);
  }

  public destroyElements(name: string[]): void {
    name.map(name => this.load.removeOneScript(name));
  }

  public removeStyles(): void {
    this.stylesLoader.allRemoveStyles();
  }
}
