import { IError } from "@core/entities";
import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { UsersState } from "@modules/users/entities";
import * as UsersAction from "@modules/users/store/actions/users.action";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

export const errorsAdapter: EntityAdapter<IError> = createEntityAdapter<IError>({
  selectId: b => b.id,
  sortComparer: (bA, bB) => (bA.id > bB.id ? 1 : -1),
});

const initialState: UsersState = {
  ui: {
    isLoading: true,
    isForm: false,
    isModal: false,
    loaderBtn: false,
    loaderBtnModal: false,
  },
  upsertUserBasicAttributes: {},
  upsertUserPickingPoints: [],
  upsertUserRetailers: [],
  users: [],
  upsertErrors: errorsAdapter.getInitialState({ total: 0 }),
};

const usersReduce = createReducer(
  initialState,
  on(UsersAction.clearAll, () => initialState),
  on(UsersAction.uiUsers, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(UsersAction.setUsers, (state, { payload }) => ({ ...state, users: payload })),
  on(UsersAction.setUpsertRetailers, (state, { payload }) => ({ ...state, upsertUserRetailers: payload })),
  on(UsersAction.setUpsertPickingPoints, (state, { payload }) => ({ ...state, upsertUserPickingPoints: payload })),
  on(UsersAction.addOneError, (state, { error }) => ({ ...state, upsertErrors: errorsAdapter.addOne(error, state.upsertErrors) })),
  on(UsersAction.removeOneError, (state, { id }) => ({ ...state, upsertErrors: errorsAdapter.removeOne(id, state.upsertErrors) })),
  on(UsersAction.setManyErrors, (state, { errors }) => ({ ...state, upsertErrors: errorsAdapter.setAll(errors, state.upsertErrors) })),
  on(UsersAction.setUpsertUser, (state, { payload }) => ({
    ...state,
    upsertUserBasicAttributes: { ...state.upsertUserBasicAttributes, ...payload },
  })),
  on(UsersAction.wipeUpsertState, state => ({
    ...state,
    upsertErrors: errorsAdapter.removeAll(state.upsertErrors),
    upsertUserBasicAttributes: {},
    upsertUserRetailers: [],
    upsertUserPickingPoints: [],
  }))
);

export function UsersReducer(state: UsersState, action: Action) {
  return usersReduce(state, action);
}

export const getUsersStore = createFeatureSelector<UsersState>("users");

export const getUiUsers = createSelector(getUsersStore, ({ ui }) => ui);
export const getAllUsers = createSelector(getUsersStore, ({ users }) => users);
export const getUpsertUser = createSelector(getUsersStore, ({ upsertUserBasicAttributes: upsertUser }) => upsertUser);
export const getUpsertUserRetailers = createSelector(getUsersStore, ({ upsertUserRetailers }) => upsertUserRetailers);
export const getUpsertUserPickingPoints = createSelector(getUsersStore, ({ upsertUserPickingPoints }) => upsertUserPickingPoints);
export const getUpsertErrorsEntities = createSelector(getUsersStore, ({ upsertErrors }) => upsertErrors);
