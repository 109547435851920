import {
  CreateOrderReducerState,
  isArbitraryStop,
  OrderStop,
  OrderStopType,
  OrderType,
} from "@app/modules/create-order/entities/create-order.entities";
import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import * as CreateOrderAction from "@modules/create-order/store/actions/create-order.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

interface ReducerState extends CreateOrderReducerState, EntityState<OrderStop> {}

function getId(pinfo: OrderStop): number {
  return pinfo.id;
}

export const orderStopAdapter: EntityAdapter<OrderStop> = createEntityAdapter<OrderStop>({
  selectId: getId,
  sortComparer: (piA, piB) => (piA.id > piB.id ? 1 : -1),
});

const initialState: ReducerState = orderStopAdapter.getInitialState({
  orderId: undefined,
  rappiOrderId: undefined,
  step: 1,
  ui: {
    loaderBtn: false,
    openMainPPPickerModal: true,
  },
  user: undefined,
  details: undefined,
  deliveryTypes: [],
  totalValue: 0,
  terminalPickingPoint: undefined,
});

const createOrderReducer = createReducer(
  initialState,
  on(CreateOrderAction.clearAll, () => initialState),
  on(CreateOrderAction.uiCreateOrder, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(CreateOrderAction.changeStep, (state, { step }) => ({ ...state, step })),
  on(CreateOrderAction.deleteStop, (state, { id }) => orderStopAdapter.removeOne(id, state)),
  on(CreateOrderAction.modifyOrderId, (state, { orderId }) => ({ ...state, orderId })),
  on(CreateOrderAction.modifyRappiOrderId, (state, { rappiOrderId }) => ({ ...state, rappiOrderId })),
  on(CreateOrderAction.modifyTotalValue, (state, { totalValue }) => ({ ...state, totalValue })),
  on(CreateOrderAction.modifyUser, (state, { user }) => ({ ...state, user: { ...state.user, ...user } })),
  on(CreateOrderAction.modifyPaymentMethod, (state, { paymentMethod }) => ({ ...state, details: { ...state.details, paymentMethod } })),
  on(CreateOrderAction.modifyTerminalPickingPoint, (state, { terminalPickingPoint }) => ({ ...state, terminalPickingPoint })),
  on(CreateOrderAction.setDeliveryTypes, (state, { payload }) => ({ ...state, deliveryTypes: payload })),
  on(CreateOrderAction.setEstimatedPrice, (state, { payload }) => ({ ...state, estimatedPrice: payload })),
  on(CreateOrderAction.modifyDetails, (state, { details }) => ({ ...state, details: { ...state.details, ...details } })),
  on(CreateOrderAction.modifyOrderStop, (state, { changes, id }) => {
    const stop = state.entities[id];
    if (!!stop && !isArbitraryStop(stop)) {
      return orderStopAdapter.updateOne(
        {
          id,
          changes: {
            ...stop,
            data: {
              ...stop.data,
              ...changes,
            },
          },
        },
        state
      );
    }
  }),
  on(CreateOrderAction.modifyDropOff, (state, { dropOff }) => {
    for (const key in state.entities) {
      if (Object.prototype.hasOwnProperty.call(state.entities, key)) {
        const o = state.entities[key];
        if (o.stopType === OrderStopType.ARBITRARY) {
          return orderStopAdapter.updateOne({ id: o.id, changes: { ...o, data: { ...o.data, ...dropOff } } }, state);
        }
      }
    }
  }),
  on(CreateOrderAction.addOrderStop, state => {
    const s = state as ReducerState;
    return orderStopAdapter.addOne(
      {
        id: s.entities[s?.ids[(s.ids?.length ?? 2) - 2]].id + 1,
        stopType: OrderStopType.PICKING_POINT,
        data: {
          products: [],
          pickingPoint: null,
          instructions: "",
        },
      },
      state
    );
  }),
  on(CreateOrderAction.initOrderStops, (state, { orderType, pickingPoint, products, userInstructions, ppInstructions }) => {
    return orderStopAdapter.setAll(
      [
        {
          id: orderType === OrderType.BASIC ? 1000000 : 0,
          stopType: OrderStopType.ARBITRARY,
          data: {
            products: [],
            instructions: userInstructions ?? "",
          },
        },
        {
          id: orderType === OrderType.BASIC ? 0 : 1,
          stopType: OrderStopType.PICKING_POINT,
          data: {
            products: products ?? [],
            pickingPoint: pickingPoint ?? null,
            instructions: ppInstructions ?? "",
          },
        },
      ],
      state
    );
  })
);

export function CreateOrderReducer(state: ReducerState, action: Action) {
  return createOrderReducer(state, action);
}

export const getCreateOrder = createFeatureSelector<ReducerState>("createOrder");

// entity selectors
export const {
  selectAll: selectOrderStopArray,
  selectEntities: selectOrderStopEntities,
  selectTotal: selectOrderStopAmount,
} = orderStopAdapter.getSelectors(getCreateOrder);
export const selectOrderStopById = (id: number) => createSelector(selectOrderStopEntities, entities => entities[id]);

export const getCurrentStep = createSelector(getCreateOrder, ({ step }) => step);
export const getUiCreateOrder = createSelector(getCreateOrder, ({ ui }) => ui);
export const getOrderId = createSelector(getCreateOrder, ({ orderId }) => orderId);
export const getRappiOrderId = createSelector(getCreateOrder, ({ rappiOrderId }) => rappiOrderId);
export const getTotalValue = createSelector(getCreateOrder, ({ totalValue }) => totalValue);
export const getUser = createSelector(getCreateOrder, ({ user }) => user);
export const getPaymentMethod = createSelector(getCreateOrder, ({ details }) => details?.paymentMethod);
export const getDeliveryTypes = createSelector(getCreateOrder, ({ deliveryTypes }) => deliveryTypes);
export const getEstimatedPrice = createSelector(getCreateOrder, ({ estimatedPrice }) => estimatedPrice);
export const getDetails = createSelector(getCreateOrder, ({ details }) => details);
export const getTerminalPickingPoint = createSelector(getCreateOrder, ({ terminalPickingPoint }) => terminalPickingPoint);
