import { OrdersState, OrdersType } from "@app/modules/orders/entities/orders.entities";
import * as OrdersAction from "@app/modules/orders/store/actions/orders.action";
import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

export const ordersPerPage: number = 50;

const initialState: OrdersState = {
  selectedOrder: null,
  selectedPendingOrder: null,
  ui: {
    loaderBtnCancel: false,
    loadingOrderDetail: false,
    loadingOrderList: false,
    loadingExcelExport: false,
    isModalCancelShow: false,
    loaderBtn: false,
    isLoading: true,
    hasData: false,
    isForm: false,
    deleteSuccess: false,
    lastArchivedOrdersPage: false,
    openExcelDialog: false,
    openColumnSettingsDialog: false,
    columns: [],
    orderBy: null,
    showRemoveDebtModal: false,
    pageNumber: 1,
  },
  searchInput: "",
  activeOrdersList: [],
  archivedOrdersList: [],
  pendingOrdersList: [],
  selectedOrderId: null,
  pendingOrdersSelectedPickingPoint: null,
  ordersType: OrdersType.ACTIVE,
};

const ordersReducer = createReducer(
  initialState,
  on(OrdersAction.clearAllActiveOrders, () => initialState),
  on(OrdersAction.setSelectedOrder, (state, { payload }) => ({ ...state, selectedOrder: payload, selectedPendingOrder: null })),
  on(OrdersAction.setActiveOrdersList, (state, { payload }) => ({ ...state, activeOrdersList: payload })),
  on(OrdersAction.setArchivedOrdersList, (state, { payload }) => ({ ...state, archivedOrdersList: payload })),
  on(OrdersAction.setPendingOrdersList, (state, { payload }) => ({ ...state, pendingOrdersList: payload })),
  on(OrdersAction.setSelectedPendingOrder, (state, { payload }) => ({ ...state, selectedPendingOrder: payload, selectedOrder: null })),
  on(OrdersAction.setPendingOrdersSelectedPickingPoint, (state, { payload }) => ({ ...state, pendingOrdersSelectedPickingPoint: payload })),
  on(OrdersAction.toggleOrdersType, (state, { payload }) => ({ ...state, ordersType: payload, searchInput: "" })),
  on(OrdersAction.uiOrders, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(OrdersAction.inputSearch, (state, { inputSearch: searchInput }) => ({ ...state, searchInput }))
);

export function OrdersReducer(state: OrdersState, action: Action) {
  return ordersReducer(state, action);
}

export const selectOrdersReducerState = createFeatureSelector<OrdersState>("activeOrders");

export const getOrdersUi = createSelector(selectOrdersReducerState, ({ ui }) => ui);
export const getSelectedOrder = createSelector(selectOrdersReducerState, ({ selectedOrder }) => selectedOrder);
export const getSelectedOrderId = createSelector(selectOrdersReducerState, ({ selectedOrderId }) => selectedOrderId);
export const getSelectedPendingOrder = createSelector(selectOrdersReducerState, ({ selectedPendingOrder }) => selectedPendingOrder);
export const getAllActiveOrders = createSelector(selectOrdersReducerState, ({ activeOrdersList }) => activeOrdersList);
export const getAllArchivedOrders = createSelector(selectOrdersReducerState, ({ archivedOrdersList }) => archivedOrdersList);
export const getAllPendingOrders = createSelector(selectOrdersReducerState, ({ pendingOrdersList }) => pendingOrdersList);
export const getOrdersType = createSelector(selectOrdersReducerState, ({ ordersType }) => ordersType);
export const getSearchInput = createSelector(selectOrdersReducerState, ({ searchInput }) => searchInput);
export const getPendingOrdersSelectedPickingPoint = createSelector(
  selectOrdersReducerState,
  ({ pendingOrdersSelectedPickingPoint }) => pendingOrdersSelectedPickingPoint
);
