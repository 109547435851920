import { ModuleWithProviders } from "@angular/core";
import { StoreModule } from "@ngrx/store";

import AppStore from "./store-reducer.util";

export class StoreUtil {
  public static globalStore(): Array<ModuleWithProviders<any>> {
    return [StoreModule.forRoot(AppStore)];
  }
}
