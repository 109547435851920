import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PickingPoint } from "@core/entities";
import { environment as ENV } from "@environments/environment";
import { Observable } from "rxjs";

export const PP_QUERY_LIMIT = 200;

@Injectable()
export class PickingPointService {
  constructor(private http: HttpClient) {}

  public createPickingPoint(values: Omit<PickingPoint, "id">): Observable<any> {
    return this.http.post<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point`, values);
  }

  public upsertPickingPoints(values: PickingPoint[]): Array<Observable<any>> {
    const promises = [];
    const create = values.filter(v => !v.id);
    const update = values.filter(v => !!v.id);
    if (create.length > 0) {
      promises.push(
        this.http.post<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point/batch-create/`, { data: create })
      );
    }
    if (update.length > 0) {
      promises.push(
        this.http.put<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point/batch-update/`, { data: update })
      );
    }
    return promises;
  }

  public updatePickingPointStatus(values: Partial<PickingPoint>): Observable<any> {
    return this.http.put<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point`, values);
  }

  public getPickupPointById(id: number): Observable<any> {
    return this.http.get<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point/view/${id}`);
  }

  public getAllPickingPoints(limit: number = PP_QUERY_LIMIT, offset: number = 0, retailerIds: number[], search: string): Observable<any> {
    const body = {
      limit,
      offset,
      retailerIds,
      search,
    };
    return this.http.post<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point/list`, body);
  }

  public deletePickingPoint(id: any): Observable<any> {
    const options = {
      headers: null,
      body: { id },
    };
    return this.http.delete<any>(`${ENV.gateway.base_url}${ENV.gateway.gateway_prefix}picking-point`, options);
  }
}
