import { PickingPoint, Retailer } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { CompanySettingsState, PickingPointCheckin } from "../../entities";

// Interfaces
export interface PickingPointParams {
  offset: number;
  input?: string;
}

export enum CompanyActions {
  ClearAll = "[COMPANY-SETTINGS] Clear store",
  Empty = "[COMPANY-SETTINGS] Empty",
  UiCompanySettings = "[COMPANY-SETTINGS] Update Company Settings Ui",
  FetchPickingPoints = "[COMPANY-SETTINGS] Fetch picking points",
  ModifyPendingChanges = "[COMPANY-SETTINGS] Modify pendingChanges",
  SaveCompany = "[COMPANY-SETTINGS] Save company",
  SaveTipSettings = "[COMPANY-SETTINGS] Save tip settings",
  SetCheckinStoresAmount = "[COMPANY-SETTINGS] Set Checkin Stores Amount",
}

export const clearAll = createAction(CompanyActions.ClearAll);
export const empty = createAction(CompanyActions.Empty);
export const uiCompanySettings = createAction(
  CompanyActions.UiCompanySettings,
  props<{ ui: Array<AdapterUi<CompanySettingsState["ui"]>> }>()
);
export const fetchPickingPoints = createAction(CompanyActions.FetchPickingPoints, props<{ params: PickingPointParams }>());
export const saveCompany = createAction(
  CompanyActions.SaveCompany,
  props<{ pickingpoints: PickingPointCheckin[]; retailer: Partial<Retailer>; logo?: File }>()
);
export const saveTipSettings = createAction(
  CompanyActions.SaveCompany,
  props<{ pickingpoints: PickingPoint[]; retailer: Partial<Retailer> }>()
);
export const modifyPendingChanges = createAction(CompanyActions.ModifyPendingChanges, props<{ pendingChanges: boolean }>());
export const setCheckinStoresAmount = createAction(CompanyActions.SetCheckinStoresAmount, props<{ checkinStoresAmount: number }>());
