import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { StorageMap } from "@ngx-pwa/local-storage";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { currentRetailerStorageKey, localStorageKey, retailersStorageKey, userSessionStorageKey } from "@core/constants";
import { AppState } from "@core/store";
import * as actions from "../store/actions/login.action";

import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { AuthState, CargoAuthResponse, LoginFormData, PasswordResetBody, RetailerInfo, UiAuth } from "@modules/auth/entities";
import * as AuthReducer from "@modules/auth/store/reducers/auth.reducer";

@Injectable({ providedIn: "root" })
export class AuthFacade {
  getUi$: Observable<UiAuth> = this.store.pipe(select(AuthReducer.getUiAuth));
  getAppUserId$: Observable<number> = this.store.pipe(select(AuthReducer.getAppUserId));

  constructor(private store: Store<AppState>, private storage: StorageMap, private router: Router, private auth0Service: AuthService) {}

  get getCurrentView$(): Observable<string> {
    return this.getUi$.pipe(map(ui => ui.currentView));
  }

  get getDataAppUserId$(): Observable<number> {
    return this.getAppUserId$;
  }

  get isLoaderBtn$(): Observable<boolean> {
    return this.getUi$.pipe(map(ui => ui.loadBtn));
  }

  public doLoginAction(formData: LoginFormData) {
    this.store.dispatch(
      actions.loginAction({
        formdata: { ...formData },
      })
    );
  }

  public doLogout() {
    combineLatest([
      // this.auth0Service.logout(),
      this.storage.delete(localStorageKey),
      this.storage.delete(currentRetailerStorageKey),
      this.storage.delete(userSessionStorageKey),
      this.storage.delete(retailersStorageKey),
    ]).subscribe(() => this.router.navigate(["/auth"]));
  }

  public getAuth(): Observable<boolean> {
    return this.storage.watch(localStorageKey).pipe(
      map((authInfo: CargoAuthResponse) => {
        const token = authInfo?.access_token;
        return token && token?.length > 0;
      })
    );
  }

  public async createRetailer(payload: RetailerInfo) {
    this.setUi([{ name: "loadBtn", value: true }]);
    return await this.store.dispatch(actions.createRetailer({ payload }));
  }

  public async passwordReset(payload: PasswordResetBody) {
    this.setUi([{ name: "loadBtn", value: true }]);
    return await this.store.dispatch(actions.passwordReset({ payload }));
  }

  public async requestPasswordReset(email: string) {
    this.setUi([{ name: "loadBtn", value: true }]);
    return await this.store.dispatch(actions.requestPasswordReset({ email }));
  }

  public setUi(setUi: Array<AdapterUi<AuthState["ui"]>>): void {
    this.store.dispatch(actions.uiAuth({ ui: setUi }));
  }
}
