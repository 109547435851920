import { Coords, OrderStopType, PaymentMethod, Product, VehicleType } from "@app/modules/create-order/entities/create-order.entities";
import { OrderStatusResDTOV3, PickingPoint, TransportMediaType } from "@core/entities";
import { Modify, PickKeysFromUnionType } from "@core/utils/ts.utils";
import { ActiveOrdersSelectionSetType, ArchivedOrdersSelectionSetType } from "../constants";

export enum OrdersType {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  PENDING = "PENDING",
}

export enum ETAType {
  "USER" = "USER",
  "STOP" = "STOP",
  "STORE" = "STORE",
}

export interface ActiveOrderQueryParams {
  pickingPointId: number;
  inputSearch?: string;
  status?: number[];
  orderBy?: OrderBy;
}

export interface ArchivedOrderQueryParams extends ActiveOrderQueryParams {
  pageNumber?: number;
}

export interface SearchOrderQueryParams extends ArchivedOrderQueryParams {
  ordersType: OrdersType;
}

export interface OrderBy {
  name: string;
  type: "ASC" | "DESC";
}

export interface OrderColumn {
  id: string;
  optional: boolean;
  orderType?: "ACTIVE" | "ARCHIVED";
}

export interface OrdersState {
  ui: UiOrders;
  selectedOrderId: string;
  selectedOrder: DetailOrder | null;
  selectedPendingOrder: BoxPendingOrder | null;
  activeOrdersList: ActiveListOrder[];
  archivedOrdersList: ArchivedListOrder[];
  pendingOrdersList: BoxPendingOrder[];
  pendingOrdersSelectedPickingPoint?: PickingPoint;
  ordersType: OrdersType;
  searchInput?: string;
}

export interface StatusFilterOption {
  status: string;
  status_ids: number[];
}

export interface UiOrders {
  loaderBtnCancel?: boolean;
  loadingOrderDetail?: boolean;
  loadingOrderList?: boolean;
  loadingExcelExport?: boolean;
  isModalCancelShow?: boolean;
  showRemoveDebtModal?: boolean;
  loaderBtn?: boolean;
  isLoading?: boolean;
  hasData?: boolean;
  isForm?: boolean;
  deleteSuccess?: boolean;
  lastArchivedOrdersPage?: boolean;
  columns: string[];
  openColumnSettingsDialog: boolean;
  openExcelDialog: boolean;
  orderBy?: OrderBy;
  pageNumber: number;
}

export type ArchivedListOrder = Modify<
  ArchivedOrderListResponse & {
    showHandshake: boolean;
    isInverse: boolean;
  },
  {
    transport_media_type: TransportMediaType;
  }
>;

export type ActiveListOrder = Modify<
  ActiveOrderListResponse & {
    showHandoff: boolean;
    showHandshake: boolean;
    isInverse: boolean;
  },
  {
    transport_media_type: TransportMediaType;
  }
>;

export interface OrderPagination {
  perPage: number;
  currentPage: number;
  from: number;
  to: number;
  lastPage?: number;
}

export interface OrderUpdate {
  id: number;
  order_id: number;
  rappi_order_id: number;
  order_status_id: number;
  core_status: string;
  updated_at: string;
}
export interface OrderModification {
  created_at: string;
  id: number;
  order_id: number;
  type: string;
  rappi_order_id: number;
}

export interface DetailActionPoint {
  address: string;
  check_in_required: boolean;
  city: string;
  comments?: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  description: string;
  executed: boolean;
  external_picking_point_id: string | number | null;
  id: string;
  index: number;
  instructions: string;
  lat: number;
  lng: number;
  picking_point_id: number | null;
  products: Product[];
  return_allowed: boolean;
  zip_code: any;
  picking_point_name: string;
  stop_type: OrderStopType;
}

export interface ETA {
  time: number;
  total: number;
  type: ETAType;
}

export interface DetailOrder {
  action_points: DetailActionPoint[];
  arrival_time: string;
  cancelation_reason: string;
  cancellation_username: string | null;
  cancelled_at: string;
  cargo_order_id: string;
  collect_cash: number;
  client: OrderClient;
  courier: Courier;
  created_at: string;
  debt_cancellation_user_name?: string;
  debt_cancelled_at?: string;
  deliver_at: string;
  eta: ETA | null;
  external_order_id: string;
  final_delivery_comments?: string;
  final_delivery_lat?: number;
  final_delivery_lng?: number;
  in_store_reference_id?: string;
  final_delivery_point_image?: string;
  handoff_code: string | null;
  handshake_code: string | null;
  order_modification: OrderModification[];
  order_status_id: number;
  order_update: OrderUpdate[];
  origin: string;
  partner_cancelation_reason: string | null;
  payment_method: PaymentMethod;
  price: any;
  rappi_order_id: string;
  requires_handshake: boolean;
  retailer_id: string;
  state: string;
  total_value: number;
  tracking_code: string;
  vehicle_type: VehicleType;
  version: number | null;
  was_cancelled_by_integration: boolean;
  was_processed_by_agent: boolean;
}

export interface OrderClient {
  address: string;
  email: string;
  first_name: string;
  last_name: string;
  lat: number;
  lng: number;
  phone: string;
}

export interface StoreKeeperProps {
  coords?: Coords;
  transport_media_type?: TransportMediaType;
}

export interface Courier {
  first_name: string;
  last_name: string;
  id: number;
  phone: string;
  lat: number;
  lng: number;
  transport_media_type: TransportMediaType;
}

export interface BoxPendingOrder {
  uuid: string;
  scheduled: boolean;
  merchant_id: string;
  external_reference: string;
  short_external_reference: string;
  origin: {
    id: string;
    name: string;
  };
  sub_total: number;
  total_price: number;
  final_total_price: number;
  delivery_fee: number;
  delivery_mode: "DELIVERY";
  delivered_by: "CARGO";
  ready_to_deliver: boolean;
  preparation_start: string;
  delivery_at: string;
  order_created_at: string;
  approved_at: string | null;
  status: {
    id: "CONFIRMED";
    name: "Confirmado";
  };
  scheduled_at: string | null;
  marketplace_name: string;
  observations: string;
  cancellation_requested: false;
  customer: {
    uuid: string;
    name: string;
    document: string;
    phone: string;
    orders_count_on_restaurant: number;
  };
  address: {
    formatted: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string;
    street_name: string;
    postal_code: string;
    street_number: string;
    complement: string;
    lat: number;
    lng: number;
  };
  items: {
    uuid: string;
    name: string;
    quantity: number;
    price: number;
    sub_items_price: number;
    total_price: number;
    discount: number;
    addition: number;
    external_code: string;
    external_id: string;
    observations: string;
    height: number;
    width: number;
    length: number;
    weight: number;
  }[];
  payments: [];
  benefits: [];
  need_approve: boolean;
  confirmation_code: string | null;
  created_at: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

export type ActiveOrderListResponse = PickKeysFromUnionType<OrderStatusResDTOV3, ActiveOrdersSelectionSetType[number]> & {
  picking_point: Partial<PickingPoint>;
};
export type ArchivedOrderListResponse = PickKeysFromUnionType<OrderStatusResDTOV3, ArchivedOrdersSelectionSetType[number]> & {
  picking_point: Partial<PickingPoint>;
};
