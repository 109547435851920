import {
  ActiveListOrder,
  ActiveOrderQueryParams,
  ArchivedListOrder,
  ArchivedOrderQueryParams,
  BoxPendingOrder,
  DetailOrder,
  OrdersState,
  OrdersType,
  SearchOrderQueryParams,
} from "@app/modules/orders/entities/orders.entities";
import { PickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";

export enum OrdersAction {
  CancelOrder = "[ORDERS] Cancel order",
  ClearAllActiveOrders = "[ORDERS] Clear all Active orders",
  Empty = "[ORDERS] Empty",
  ExportOrders = "[ORDERS] Export Orders",
  FetchActiveOrderList = "[ORDERS] Fetch Active Order list",
  FetchArchivedOrderList = "[ORDERS] Fetch Archived Order list",
  FetchPendingOrderList = "[ORDERS] Fetch Pending Order list",
  FetchOrderDetail = "[ORDERS] Fetch Order by Id",
  InputSearch = "[ORDERS] Search Input",
  NotifyDeliveredProducts = "[ORDERS] Notify delivered products to RT",
  RemoveDebt = "[ORDERS] Remove Debt From Order",
  SetActiveOrdersList = "[ORDERS] Set Active Orders List",
  SetArchivedOrderList = "[ORDERS] Set Archived Orders List",
  SetPendingOrdersList = "[ORDERS] Set Pending Orders List",
  SetPendingOrdersSelectedPickingPoint = "[ORDERS] Set Pending Orders Picking Point",
  SetSelectedOrder = "[ORDERS] Set Selected Order",
  AcceptPendingOrder = "[ORDERS] Accept Pending Order",
  SetSelectedPendingOrder = "[ORDERS] Set Selected Pending Order",
  ToggleOrdersType = "[ORDERS] Toggle between archived and active orders",
  UiOrders = "[ORDERS] Orders UI",
}

export const uiOrders = createAction(OrdersAction.UiOrders, props<{ ui: Array<AdapterUi<OrdersState["ui"]>> }>());
export const Empty = createAction(OrdersAction.Empty);
export const inputSearch = createAction(OrdersAction.InputSearch, props<SearchOrderQueryParams>());

// active orders
export const clearAllActiveOrders = createAction(OrdersAction.ClearAllActiveOrders);
export const fetchActiveOrderList = createAction(OrdersAction.FetchActiveOrderList, props<ActiveOrderQueryParams>());
export const setActiveOrdersList = createAction(OrdersAction.SetActiveOrdersList, props<{ payload: ActiveListOrder[] }>());
export const notifyDeliveredProducts = createAction(OrdersAction.NotifyDeliveredProducts, props<{ rappiId: string; cargoId: string }>());
export const cancelOrder = createAction(OrdersAction.CancelOrder, props<{ orderId: string; pickingPointId: number; reason?: string }>());

// archived orders
export const fetchArchivedOrderList = createAction(OrdersAction.FetchArchivedOrderList, props<ArchivedOrderQueryParams>());
export const setArchivedOrdersList = createAction(OrdersAction.SetArchivedOrderList, props<{ payload: ArchivedListOrder[] }>());
export const removeDebt = createAction(OrdersAction.RemoveDebt, props<{ payload: string }>());

// Pending orders
export const setPendingOrdersList = createAction(OrdersAction.SetPendingOrdersList, props<{ payload: BoxPendingOrder[] }>());
export const acceptPendingOrders = createAction(OrdersAction.AcceptPendingOrder, props<{ orderId: string; pickingPointId: number }>());
export const fetchPendingOrdersList = createAction(OrdersAction.FetchPendingOrderList, props<{ payload: number }>());
export const setPendingOrdersSelectedPickingPoint = createAction(
  OrdersAction.SetPendingOrdersSelectedPickingPoint,
  props<{ payload: PickingPoint }>()
);
export const setSelectedPendingOrder = createAction(OrdersAction.SetSelectedPendingOrder, props<{ payload: BoxPendingOrder }>());

// shared
export const fetchOrderDetail = createAction(
  OrdersAction.FetchOrderDetail,
  props<Partial<{ cargo_order_id: string; rappi_order_id: string }>>()
);
export const setSelectedOrder = createAction(OrdersAction.SetSelectedOrder, props<{ payload: DetailOrder }>());
export const toggleOrdersType = createAction(OrdersAction.ToggleOrdersType, props<{ payload: OrdersType }>());
export const exportOrders = createAction(OrdersAction.ExportOrders);
