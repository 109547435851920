import * as moment from "moment";

export class DateParser {
  static dayMonth(timestamp: string) {
    moment.locale("es-mx");
    return moment.utc(timestamp);
  }

  static roundUpHour(timestamp: string) {
    const m = moment.utc(timestamp);
    const roundUp = m.minute() || m.second() || m.millisecond() ? m.add(1, "hour").startOf("hour") : m.startOf("hour");
    m.locale("es-mx");
    return roundUp;
  }

  static genericFormat(timestamp: string) {
    const m = moment(timestamp);
    m.locale("es-mx");
    return m;
  }
}
