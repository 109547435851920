import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { CONTROL_TOWER_SUBSECTIONS } from "../../constants";
import { CONTROL_TOWER_SECTIONS, ControlTowerState } from "../../entities";
import * as ControlTowerAction from "../actions/control-tower.action";

const initialState: ControlTowerState = {
  ui: {
    selectedTab: CONTROL_TOWER_SECTIONS.LIVE,
    selectedSubTab: CONTROL_TOWER_SUBSECTIONS[CONTROL_TOWER_SECTIONS.LIVE]["ASSIGNMENT"],
    isLoadingRecords: false,
    isLoadingCount: false,
    isLoadingAction: false,
    selectedPickingPoint: undefined,
    openAddIncentivesModal: false,
    openCancelOrderModal: false,
  },
  countByOrderId: [],
  orderList: [],
  countByPickingPoints: [],
  selectedOrder: undefined,
};

const controlTowerReducer = createReducer(
  initialState,
  on(ControlTowerAction.clearAll, () => initialState),
  on(ControlTowerAction.setControlTowerUi, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(ControlTowerAction.setControlTowerSelectedOrder, (state, { order }) => ({ ...state, selectedOrder: order })),
  on(ControlTowerAction.setControlTowerCount, (state, { payload }) => ({
    ...state,
    countByOrderId: payload,
  })),
  on(ControlTowerAction.setControlTowerCountByPickingPoint, (state, { payload }) => ({
    ...state,
    countByPickingPoints: payload,
  })),
  on(ControlTowerAction.setControlTowerOrderList, (state, { payload }) => ({
    ...state,
    orderList: payload,
  }))
);

export function ControlTowerReducer(state: ControlTowerState, action: Action) {
  return controlTowerReducer(state, action);
}

export const getControlTowerState = createFeatureSelector<ControlTowerState>("controlTower");
export const getControlTowerUi = createSelector(getControlTowerState, s => s?.ui);
export const getControlTowerSelectedOrder = createSelector(getControlTowerState, s => s?.selectedOrder);
export const getCountByOrderId = createSelector(getControlTowerState, s => s?.countByOrderId);
export const getCountByPickingPoints = createSelector(getControlTowerState, s => s?.countByPickingPoints);
export const getOrderListByOrderId = createSelector(getControlTowerState, s => s?.orderList);
