import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { ReportsState } from "../../entities";
import * as ReportsAction from "../actions/reports.action";

const initialState: ReportsState = {
  ui: {
    openOrderReportCreation: false,
  },
  reports: [],
};

const reportsReduce = createReducer(
  initialState,
  on(ReportsAction.clearAll, () => initialState),
  on(ReportsAction.setReports, (state, { reports }) => ({
    ...state,
    reports,
  })),
  on(ReportsAction.deleteReport, (state, { id }) => ({
    ...state,
    reports: [...state.reports.filter(report => id !== report.id)],
  }))
);

export function ReportsReducer(state: ReportsState, action: Action) {
  return reportsReduce(state, action);
}

export const getReportsState = createFeatureSelector<ReportsState>("reports");
export const getReports = createSelector(getReportsState, ({ reports }) => reports);
