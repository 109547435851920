import { PickingPoint } from "@core/entities";
import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import {
  AdditionalRevisionInfoInput,
  BillingPeriodGroupEntity,
  CreateOrderRevisionInput,
  OrderRevisionStatus,
  SalesSummary,
  SettlementState,
} from "../../entities";

export enum SettlementAction {
  ClearAll = "[SETTLEMENT] Clear",
  SettlementUi = "[SETTLEMENT] Ui",
  FetchOrders = "[SETTLEMENT] Fetch Orders",
  FetchOrderDetail = "[SETTLEMENT] Fetch order detail",
  AppendOrders = "[SETTLEMENT Append orders",
  SetOrders = "[SETTLEMENT Set successful orders",
  SetOrderDetail = "[SETTLEMENT Set order detail",
  SetSelectedOrderId = "[SETTLEMENT Set selected order id",
  SetOrderRevisionId = "[SETTLEMENT Set order revision id",
  FetchPickingPoints = "[SETTLEMENT] Fetch picking points",
  SetRange = "[SETTLEMENT] Set Range",
  SetOffset = "[SETTLEMENT] Set Offset",
  SetSearchInput = "[SETTLEMENT] Set Search Input",
  SetPickingPointsForSettlement = "[SETTLEMENT] Set picking points",
  SetPage = "[SETTLEMENT] Set Page",
  SetType = "[SETTLEMENT] Set Type",
  GetRevision = "[SETTLEMENT] Get Revision",
  SetRevisionDetail = "[SETTLEMENT] Set Revision Detail",
  CreateRevision = "[SETTLEMENT] Create Revision",
  AddRevisionInfo = "[SETTLEMENT] Add Revision Info",
  ModifyRevisionInfo = "[SETTLEMENT] Modify Revision Info",
  GetBillingPeriods = "[SETTLEMENT] Get Billing Periods",
  SetBillingPeriods = "[SETTLEMENT] Set Billing Periods",
  UploadBatchConciliation = "[SETTLEMENT] Upload Batch Conciliation",
  SetBatchConciliationResponse = "[SETTLEMENT] Set Batch Conciliation Response",
  GetBillingEmails = "[SETTLEMENT] Get Billing Emails",
  SetBillingEmails = "[SETTLEMENT] Set Billing Emails",
  UpdateBillingEmails = "[SETTLEMENT] Update Billing Emails",
  GetSalesSummaries = "[SETTLEMENT] Get Sales Summaries",
  SetSalesSummaries = "[SETTLEMENT] Set Sales Summaries",
  UpdateSalesSummaryPage = "[SETTLEMENT] Update Sales Summary Page",
}

export const clearAll = createAction(SettlementAction.ClearAll);
export const fetchOrders = createAction(
  SettlementAction.FetchOrders,
  props<{
    page: number;
    retailerId: number;
    start_date?: string;
    end_date?: string;
    picking_point_id?: number[];
    id_custom_search?: string;
    append: boolean;
    orderType: SettlementState["orderType"];
    revisionStatus?: OrderRevisionStatus;
  }>()
);
export const appendSuccessful = createAction(SettlementAction.AppendOrders, props<{ orders: any[] }>());
export const settlementUi = createAction(SettlementAction.SettlementUi, props<{ ui: Array<AdapterUi<SettlementState["ui"]>> }>());
export const fetchOrderDetail = createAction(SettlementAction.FetchOrderDetail, props<{ orderId: string; retailerId: number }>());
export const setSuccessful = createAction(SettlementAction.SetOrders, props<{ orders: any[] }>());
export const setOrderDetail = createAction(SettlementAction.SetOrderDetail, props<{ order: any }>());
export const setSelectedOrderId = createAction(SettlementAction.SetSelectedOrderId, props<{ id: string | undefined }>());
export const setRange = createAction(
  SettlementAction.SetRange,
  props<{ range: { from: string | undefined; to: string | undefined } | undefined }>()
);
export const setOffset = createAction(SettlementAction.SetOffset, props<{ offset: number }>());
export const setSearchInput = createAction(SettlementAction.SetSearchInput, props<{ input: string }>());
export const setPage = createAction(SettlementAction.SetPage, props<{ page: number }>());
export const setType = createAction(SettlementAction.SetType, props<{ orderType: SettlementState["orderType"] }>());
export const fetchPickingPoints = createAction(SettlementAction.FetchPickingPoints);
export const setPickingPointsForSettlement = createAction(
  SettlementAction.SetPickingPointsForSettlement,
  props<{ payload: Array<PickingPoint> }>()
);
export const getRevision = createAction(SettlementAction.GetRevision, props<{ payload: string; retailerId: number }>());
export const setRevisionDetail = createAction(SettlementAction.SetRevisionDetail, props<{ payload: any }>());
export const createRevision = createAction(
  SettlementAction.CreateRevision,
  props<{ payload: CreateOrderRevisionInput; retailerId: number }>()
);
export const addRevisionInfo = createAction(
  SettlementAction.AddRevisionInfo,
  props<{ payload: AdditionalRevisionInfoInput; retailerId: number }>()
);
export const modifyRevisionInfo = createAction(
  SettlementAction.ModifyRevisionInfo,
  props<{ payload: Partial<SettlementState["revision"]>; retailerId: number }>()
);
export const getBillingPeriods = createAction(SettlementAction.GetBillingPeriods, props<{ retailerId: number }>());
export const setBillingPeriods = createAction(SettlementAction.SetBillingPeriods, props<{ payload: BillingPeriodGroupEntity[] }>());
export const uploadBatchConciliation = createAction(SettlementAction.UploadBatchConciliation, props<{ file: File; retailerId: number }>());
export const setBatchConciliationResponse = createAction(SettlementAction.SetBatchConciliationResponse, props<{ response: any }>());

export const getBillingEmails = createAction(SettlementAction.GetBillingEmails, props<{ retailerId: number }>());
export const setBillingEmails = createAction(SettlementAction.SetBillingEmails, props<{ payload: string[] }>());
export const updateBillingEmails = createAction(SettlementAction.UpdateBillingEmails, props<{ payload: string[]; retailerId: number }>());
export const getSalesSummaries = createAction(SettlementAction.GetSalesSummaries, props<{ retailerId: number }>());
export const setSalesSummaries = createAction(SettlementAction.SetSalesSummaries, props<{ payload: SalesSummary[] }>());
export const updateSalesSummaryPage = createAction(SettlementAction.UpdateSalesSummaryPage, props<{ payload: number }>());
