import { IError } from "@core/entities";
import * as ErrorAction from "@core/store/actions/errors.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

interface ErrorState extends EntityState<IError> {
  type: boolean;
}

function getId(pinfo: IError): string {
  return pinfo.id;
}

export const errorAdapter: EntityAdapter<IError> = createEntityAdapter<IError>({
  selectId: getId,
  sortComparer: (piA, piB) => (piA.id > piB.id ? 1 : -1),
});

const initialState: ErrorState = errorAdapter.getInitialState({
  type: false,
});

const createOrderReduce = createReducer(
  initialState,
  on(ErrorAction.clearAll, () => initialState),
  on(ErrorAction.addOneError, (state, { payload }) => errorAdapter.upsertOne(payload, state)),
  on(ErrorAction.removeOneError, (state, { id }) => errorAdapter.removeOne(id, state)),
  on(ErrorAction.addManyErrors, (state, { errors }) => errorAdapter.upsertMany(errors, state)),
  on(ErrorAction.removeManyErrors, (state, { ids }) => errorAdapter.removeMany(ids, state))
);

export function CreateOrderErrorReducer(state: ErrorState, action: Action) {
  return createOrderReduce(state, action);
}

export const getCreateOrderError = createFeatureSelector<ErrorState>("createOrderErrors");

// entity selectors
export const {
  selectAll: selectErrorArray,
  selectTotal: selectErrorAmount,
} = errorAdapter.getSelectors(getCreateOrderError);

export const selectErrorEntities = createSelector(getCreateOrderError, state => state.entities);

export const selectErrorById = (id: string) => createSelector(selectErrorEntities, entities => entities[id]);
