import { PickingPoint } from "@core/entities";
import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { BillingPeriodGroupEntity, OrderRevisionReason, SettlementState } from "../../entities";
import * as SettlementAction from "../actions/settlement.action";

interface ReducerState extends SettlementState, EntityState<PickingPoint> {}

function ppAdapterSelectId(pp: PickingPoint): number {
  return pp.id;
}

export const ppAdapter: EntityAdapter<PickingPoint> = createEntityAdapter<PickingPoint>({
  selectId: ppAdapterSelectId,
  sortComparer: (ppA, ppB) => (ppA.id > ppB.id ? 1 : -1),
});

export const billingAdapter: EntityAdapter<BillingPeriodGroupEntity> = createEntityAdapter<BillingPeriodGroupEntity>({
  selectId: b => b.paymentReferenceId,
  sortComparer: (bA, bB) => (bA.paymentReferenceId > bB.paymentReferenceId ? 1 : -1),
});

const initialState: ReducerState = ppAdapter.getInitialState({
  ui: {
    lastPage: false,
    isLoading: false,
    isUploadingBatchConciliation: false,
  },
  revision: {
    attachments: [],
    comment: "",
    reason: OrderRevisionReason.BASE_CHARGE_INCORRECT,
  },
  salesSummary: {
    summaries: [],
    page: 0,
  },
  successfulOrders: [],
  billingPeriods: billingAdapter.getInitialState({ total: 0 }),
  billingEmails: [],
  selectedOrderId: undefined,
  range: undefined,
  page: 0,
  searchInput: "",
  orderType: "SUCCESSFULL",
  batchConciliationResponse: undefined,
});

const settlementReduce = createReducer(
  initialState,
  on(SettlementAction.clearAll, () => initialState),
  on(SettlementAction.settlementUi, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(SettlementAction.setPickingPointsForSettlement, (state, { payload }) => ppAdapter.addMany(payload, state)),
  on(SettlementAction.setSelectedOrderId, (state, { id }) => ({
    ...state,
    selectedOrderId: id,
    orderDetail: undefined,
    orderRevisionDetail: undefined,
  })),
  on(SettlementAction.setOrderDetail, (state, { order }) => ({
    ...state,
    orderDetail: order,
  })),
  on(SettlementAction.setType, (state, { orderType }) => ({
    ...state,
    page: 0,
    successfulOrders: [],
    orderType,
  })),
  on(SettlementAction.appendSuccessful, (state, { orders }) => ({
    ...state,
    successfulOrders: [...state.successfulOrders, ...orders],
  })),
  on(SettlementAction.setSuccessful, (state, { orders: successfulOrders }) => ({
    ...state,
    successfulOrders,
  })),
  on(SettlementAction.setRange, (state, { range }) => ({
    ...state,
    range,
  })),
  on(SettlementAction.setPage, (state, { page }) => ({
    ...state,
    page,
  })),
  on(SettlementAction.setOffset, (state, { offset }) => ({
    ...state,
    offset,
  })),
  on(SettlementAction.setSearchInput, (state, { input: searchInput }) => ({
    ...state,
    searchInput,
  })),
  on(SettlementAction.setRevisionDetail, (state, { payload }) => {
    return {
      ...state,
      orderRevisionDetail: payload,
    };
  }),
  on(SettlementAction.modifyRevisionInfo, (state, { payload: revision }) => ({
    ...state,
    revision: {
      ...state.revision,
      ...revision,
    },
  })),
  on(SettlementAction.setBillingPeriods, (state, { payload: billingPeriods }) => ({
    ...state,
    billingPeriods: billingAdapter.setAll(billingPeriods, state.billingPeriods),
  })),
  on(SettlementAction.setBatchConciliationResponse, (state, { response }) => ({
    ...state,
    batchConciliationResponse: response,
  })),
  on(SettlementAction.setBillingEmails, (state, { payload: billingEmails }) => ({
    ...state,
    billingEmails,
  })),
  on(SettlementAction.setBillingEmails, (state, { payload: billingEmails }) => ({
    ...state,
    billingEmails,
  })),
  on(SettlementAction.updateSalesSummaryPage, (state, { payload: page }) => ({
    ...state,
    salesSummary: {
      ...state.salesSummary,
      page,
    },
  })),
  on(SettlementAction.setSalesSummaries, (state, { payload: summaries }) => ({
    ...state,
    salesSummary: {
      ...state.salesSummary,
      summaries,
    },
  }))
);

export function SettlementReducer(state: ReducerState, action: Action) {
  return settlementReduce(state, action);
}

export const selectSettlementReducerState = createFeatureSelector<ReducerState>("settlement");

// entity selectors
export const { selectAll: selectPPArray } = ppAdapter.getSelectors(selectSettlementReducerState);
export const selectPPEntities = createSelector(selectSettlementReducerState, state => state.entities);
export const getSettlementSuccesfulOrders = createSelector(selectSettlementReducerState, ({ successfulOrders }) => successfulOrders);
export const getSettlementUi = createSelector(selectSettlementReducerState, ({ ui }) => ui);
export const selectBillingPeriodEntities = createSelector(selectSettlementReducerState, state => state.billingPeriods);
