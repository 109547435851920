import { OrderStatusResDTOV3, PickingPoint } from "@core/entities";
import { Modify, PickKeysFromUnionType } from "@core/utils/ts.utils";

export interface ControlTowerState {
  ui: ControlTowerUi;
  countByOrderId: ControlTowerCount[];
  countByPickingPoints: CountByPickingPoint[];
  orderList: ControlTowerListOrder[];
  selectedOrder: ControlTowerListOrder | undefined;
}

export interface ControlTowerUi {
  selectedTab: CONTROL_TOWER_SECTIONS;
  selectedSubTab: string;
  isLoadingRecords: boolean;
  isLoadingCount: boolean;
  isLoadingAction: boolean;
  selectedPickingPoint: number;
  openAddIncentivesModal: boolean;
  openCancelOrderModal: boolean;
}

export type FilterCard = {
  id: string;
  label: [string, string];
  description: string;
  orderIds: number[];
  mainValueUnit: string;
  mainValue: number;
};

export enum PossibleControlTowerActions {
  CONTACT_SUPPORT = "CONTACT_SUPPORT",
  CONTACT_RT = "CONTACT_RT",
  CONTACT_USER = "CONTACT_USER",
  ADD_TIP = "ADD_TIP",
  REMOVE_DEBT = "REMOVE_DEBT",
  GO_TO_TRACKING_LINK = "GO_TO_TRACKING_LINK",
  CANCEL_ORDER = "CANCEL_ORDER",
}

export enum CONTROL_TOWER_SECTIONS {
  LIVE = "LIVE",
  PICKING_POINT = "PICKING_POINT",
  RETURNS = "RETURNS",
}

export type ControlTowerFilterBody = {
  order_status_ids?: number[];
  picking_point_ids?: number[];
  limit?: number;
  offset?: number;
};

export type ControlTowerCount = {
  order_status_id: number;
  count: number;
};

export const CONTROL_TOWER_ORDERS_SELECTION_SET = [
  "created_at",
  "destination_first_name",
  "destination_last_name",
  "destination_address",
  "destination_email",
  "destination_phone",
  "destination_phone_code",
  "sk_id",
  "sk_first_name",
  "sk_last_name",
  "sk_phone",
  "picking_point.name",
  "picking_point_id",
  "total_value",
  "id",
  "tracking_code",
  "handshake_code",
  "order_status_id",
  "rappi_order_id",
  "destination_address",
  "address",
  "debt_cancelled_at",
  "external_order_id",
  "handoff_code",
  "minutes_since_last_update",
] as const;

export type ControlTowerOrdersSelectionSetType = typeof CONTROL_TOWER_ORDERS_SELECTION_SET;

export type ControlTowerListOrder = Modify<
  PickKeysFromUnionType<OrderStatusResDTOV3, ControlTowerOrdersSelectionSetType[number]> & {
    picking_point: Partial<PickingPoint>;
    showHandshake: boolean;
    showHandoff: boolean;
  },
  { rappi_order_id: number; id: number }
>;

export type CountByPickingPoint = {
  picking_point_id: number;
  in_progress: null | number;
  on_the_route: null | number;
  arrive: null | number;
  in_store: null | number;
  created: null | number;
  finding_courier: null | number;
  failed_return: null | number;
  failed_delivery: null | number;
  return_in_store: null | number;
};

export type AddIncentiveBody = {
  rappi_order_id: number;
  order_id: number;
  incentive: number;
};
