import { GlobalAdapter } from "@core/store/adapters/global.adapter";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { GlobalState, PickingPoint } from "@core/entities/global.interface";
import * as GlobalAction from "@core/store/actions/global.action";
import { Mutator } from "@core/utils";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";

interface GlobalReducerState extends GlobalState, EntityState<PickingPoint> {}

function ppAdapterSelectId(pp: PickingPoint): number {
  return pp.id;
}

export const ppAdapter: EntityAdapter<PickingPoint> = createEntityAdapter<PickingPoint>({
  selectId: ppAdapterSelectId,
  sortComparer: (ppA, ppB) => (ppA.id > ppB.id ? 1 : -1),
});

const initialState: GlobalReducerState = ppAdapter.getInitialState({
  comms: {
    header: null,
    announcements: [],
    notifications: [],
  },
  serializedPickingPoints: null,
  dragCoords: null,
  checkin: null,
  onboarding: {
    hasOrders: true,
    hasPickingPoints: true,
  },
  settlement: {
    isCashless: false,
  },
  ui: {
    loadingOnboarding: false,
    loadPickingPoints: false,
    loadingCheckin: false,
    failedCheckin: false,
    loadingRetailer: false,
    loadingReport: false,
    visibleHeader: false,
    openRolesDialogModal: false,
  },
} as GlobalState);

const globalReducerCreate = createReducer(
  initialState,
  on(GlobalAction.clearAll, () => initialState),
  on(GlobalAction.setComms, (state, { payload }) => ({ ...state, comms: payload })),
  on(GlobalAction.uiGlobal, (state, { ui }) => GlobalAdapter.setUi(ui, state)),
  on(GlobalAction.setSerializedPickingPoints, (state, { payload }) => ({ ...state, serializedPickingPoints: payload })),
  on(GlobalAction.setPickingPoints, (state, { payload }) => ppAdapter.setAll(payload, state)),
  on(GlobalAction.setDragCoords, (state, { payload }) => ({ ...state, dragCoords: payload })),
  on(GlobalAction.setCheckin, (state, { checkin }) => ({ ...state, checkin: Mutator.copy(checkin) })),
  on(GlobalAction.setRetailers, (state, { retailers }) => ({ ...state, retailers })),
  on(GlobalAction.setInitialData, (state, { hasOrders, hasPickingPoints }) => ({
    ...state,
    onboarding: { hasOrders, hasPickingPoints },
    ui: { ...state.ui, loadingOnboarding: false },
  })),
  on(GlobalAction.setSettlementAttribute, (state, { settlement: newSettlement }) => ({
    ...state,
    settlement: {
      ...state.settlement,
      ...newSettlement,
    },
  }))
);

export function GlobalReducer(state: GlobalReducerState, action: Action) {
  return globalReducerCreate(state, action);
}

export const getGlobal = createFeatureSelector<GlobalReducerState>("global");

// entity selectors
export const { selectAll: selectPPArray } = ppAdapter.getSelectors(getGlobal);
export const selectPPEntities = createSelector(getGlobal, state => state.entities);

export const getComms = createSelector(getGlobal, ({ comms }) => comms);
export const getUiGlobal = createSelector(getGlobal, ({ ui }) => ui);
export const getSerializedPickingPoints = createSelector(getGlobal, ({ serializedPickingPoints }) => serializedPickingPoints);
export const getDragCoords = createSelector(getGlobal, ({ dragCoords }) => dragCoords);
export const getCheckin = createSelector(getGlobal, ({ checkin }) => checkin);
export const getHasOrders = createSelector(getGlobal, ({ onboarding }) => onboarding.hasOrders);
export const getHasPickingPoints = createSelector(getGlobal, ({ onboarding }) => onboarding.hasPickingPoints);
