import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment as ENV } from "@environments/environment";
// Guards
import { AuthenticationGuard, UnAuthenticatedGuard } from "@core/guards";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("@app/modules/auth/auth.module").then(m => m.CargoAuthModule),
    canActivate: [UnAuthenticatedGuard],
  },
  {
    path: "home",
    loadChildren: () => import("@app/modules/landing/landing.module").then(m => m.LandingModule),
    canActivate: [UnAuthenticatedGuard],
  },
  {
    path: "dashboard",
    loadChildren: () => import("@app/modules/dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
  },
  {
    path: "rappi-sync",
    loadChildren: () => import("@app/modules/rappi-sync/rappi-sync.module").then(m => m.RappiSyncModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checkin",
    loadChildren: () =>
      import("@app/modules/unauthenticated-checkin/unauthenticated-checkin.module").then(m => m.UnauthenticatedCheckinModule),
  },
  { path: "**", redirectTo: "/home", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: ENV.route_hash,
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
