export const MICRO_FRONTENDS = {
  dev: {
    google_maps: {
      name: "google_maps",
      src: "https://mf-google-maps.dev.rappi.com/static/js/bundle.js",
    },
  },
  prod: {
    google_maps: {
      name: "google_maps",
      src: "https://mf-google-maps.rappi.com/static/js/bundle.js",
    },
  },
  local: {
    google_maps: {
      name: "google_maps",
      src: "http://localhost:5000/static/js/bundle.js",
    },
  },
};
