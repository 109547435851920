import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";

// Interfaces
import { AuthState, CargoAuthResponse, LoginFormData, PasswordResetBody, RetailerInfo } from "@modules/auth/entities";

export enum LoginActions {
  ClearAll = "[Login] Clear store",
  Empty = "[Login] Empty",
  UiAuth = "[AUTH] Ui Auth",
  DoLogin = "[Login] Do Login",
  RespSuccess = "[Login] Response Login Request",
  ExchangeRequest = "[AUTH] Exchange Request",
  CreateRetailer = "[AUTH] Create Retailer",
  GoLogin = "[AUTH] Go Login",
  SetAppUserId = "[AUTH] Set Application User Id",
  FetchRetailerIds = "[AUTH] Fetch Retailer Ids",
  PasswordReset = "[AUTH] Password Reset",
  RequestPasswordReset = "[AUTH] Request Password Reset",
}

export const clearAll = createAction(LoginActions.ClearAll);
export const empty = createAction(LoginActions.Empty);
export const uiAuth = createAction(LoginActions.UiAuth, props<{ ui: Array<AdapterUi<AuthState["ui"]>> }>());
export const loginAction = createAction(LoginActions.DoLogin, props<{ formdata: LoginFormData }>());
export const respSuccessAction = createAction(
  LoginActions.RespSuccess,
  props<{ payload: { auth: CargoAuthResponse; redirect: boolean } }>()
);
export const exchangeRequest = createAction(LoginActions.ExchangeRequest, props<{ code: string }>());
export const createRetailer = createAction(LoginActions.CreateRetailer, props<{ payload: RetailerInfo }>());
export const setAppUserId = createAction(LoginActions.SetAppUserId, props<{ payload: number }>());
export const fetchRetailerIds = createAction(LoginActions.FetchRetailerIds, props<{ resp: CargoAuthResponse }>());
export const passwordReset = createAction(LoginActions.PasswordReset, props<{ payload: PasswordResetBody }>());
export const requestPasswordReset = createAction(LoginActions.RequestPasswordReset, props<{ email: string }>());
export const goLogin = createAction(LoginActions.GoLogin);
