import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { AddIncentiveBody, ControlTowerFilterBody, ControlTowerListOrder, ControlTowerState } from "../../entities";

export enum ControlTowerActions {
  ClearAll = "[CONTROL TOWER] Clear all Control Tower",
  SetControlTowerUI = "[CONTROL TOWER] Ui",
  SetControlTowerSelectedOrder = "[CONTROL TOWER] Set Control Tower Selected Order",
  SetControlTowerOrderList = "[CONTROL TOWER] Set Control Tower List",
  SetControlTowerCount = "[CONTROL TOWER] Set Control Tower Count",
  SetControlTowerCountByPickingPoint = "[CONTROL TOWER] Set Control Tower Count By Picking Point",
  FetchControlTowerCount = "[CONTROL TOWER] Fetch Control Tower Count",
  FetchControlTowerCountByPickingPoint = "[CONTROL TOWER] Fetch Control Tower Count By PickingPoint",
  FetchControlTowerListByOrderIds = "[CONTROL TOWER] Fetch Control Tower List by Order Ids",
  AddIncentivesToOrder = "[CONTROL TOWER] Add Incentives To Order",
  RemoveDebt = "[CONTROL TOWER] Remove Debt",
  CancelOrder = "[CONTROL TOWER] Cancel Order",
}

export const clearAll = createAction(ControlTowerActions.ClearAll);
export const setControlTowerUi = createAction(
  ControlTowerActions.SetControlTowerUI,
  props<{ ui: Array<AdapterUi<ControlTowerState["ui"]>> }>()
);
export const fetchControlTowerCount = createAction(
  ControlTowerActions.FetchControlTowerCount,
  props<{ payload: ControlTowerFilterBody }>()
);
export const fetchControlTowerCountByPickingPoint = createAction(
  ControlTowerActions.FetchControlTowerCountByPickingPoint,
  props<{ payload: ControlTowerFilterBody }>()
);
export const fetchControlTowerListByOrderIds = createAction(
  ControlTowerActions.FetchControlTowerListByOrderIds,
  props<{ payload: ControlTowerFilterBody }>()
);
export const setControlTowerCount = createAction(
  ControlTowerActions.SetControlTowerCount,
  props<{ payload: ControlTowerState["countByOrderId"] }>()
);
export const setControlTowerCountByPickingPoint = createAction(
  ControlTowerActions.SetControlTowerCount,
  props<{ payload: ControlTowerState["countByPickingPoints"] }>()
);
export const setControlTowerOrderList = createAction(
  ControlTowerActions.SetControlTowerOrderList,
  props<{ payload: ControlTowerListOrder[] }>()
);
export const setControlTowerSelectedOrder = createAction(
  ControlTowerActions.SetControlTowerSelectedOrder,
  props<{ order: ControlTowerListOrder }>()
);
export const addIncentivesToOrder = createAction(ControlTowerActions.AddIncentivesToOrder, props<{ payload: AddIncentiveBody }>());
export const removeDebtToOrder = createAction(ControlTowerActions.RemoveDebt, props<{ payload: number }>());
export const cancelOrder = createAction(
  ControlTowerActions.CancelOrder,
  props<{ payload: { cargoOrderId: number; reasonForCancelling: string } }>()
);
