export const I18N_VERSION = "24.8.13.1";

export type EnvironmentType = {
  placement: "dev" | "prod";
  production: boolean;
  route_hash: boolean;
  timezone: string;
  code: string;
  currency: string;
  grammar: string;
  lang: "es" | "es" | "pt";
  phone_length: number;
  max_phone_length?: number;
  max_tip: number;
  country_code: string;
  gateway: {
    base_url: string;
    gateway_prefix: string;
    settlement_prefix: string;
    csv_prefix: string;
  };
  logoUrl: string;
  maps_api: {
    api_key: string;
  };
  coordsCenter: { lat: number; lng: number };
  currencySymbol: string;
  decimalPattern: RegExp;
  decimalNumber: number;
  country: {
    defaultLocale: string;
  };
  trackingUrl: string;
  localeUrl: string;
  auth0: {
    client_id: string;
    client_secret: string;
    domain: string;
  };
  amplitude: {
    key: string;
  };
  OAuthGateway: string;
  i18n_version: string;
};
