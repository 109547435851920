import { AdapterUi } from "@core/store/adapters/global.adapter";
import { createAction, props } from "@ngrx/store";
import { AnalyticsHistogram, AnalyticsStat, AnalyticsState, Forecast, PossibleRange } from "../../entities";

export enum AnalyticsAction {
  ClearAll = "[REPORTS] Clear all analytics",
  SetAnalyticsUi = "[REPORTS] UI Analytics",
  FetchWeeklyStats = "[REPORTS] Fetch Weekly Stats",
  FetchDailyStats = "[REPORTS] Fetch Daily Stats",
  FetchMonthlyStats = "[REPORTS] Fetch Monthly Stats",
  FetchMonthlyPickingPointStats = "[REPORTS] Fetch Monthly PickingPoint Stats",
  FetchWeeklyPickingPointStats = "[REPORTS] Fetch Weekly PickingPoint Stats",
  FetchDailyPickingPointStats = "[REPORTS] Fetch Daily PickingPoint Stats",
  FetchMonthlyForecast = "[REPORTS] Fetch Monthly FOrecast",
  SetData = "[REPORTS] Set Data",
  ModifyFilters = "[REPORTS] Modify Filters",
  SetFilters = "[REPORTS] Set Filters",
  SetSelectedRange = "[REPORTS] Set Selected Range",
  SetForecast = "[REPORTS] Set Forecast",
}

export const clearAll = createAction(AnalyticsAction.ClearAll);
export const setAnalyticsUi = createAction(AnalyticsAction.SetAnalyticsUi, props<{ ui: Array<AdapterUi<AnalyticsState["ui"]>> }>());
export const fetchWeeklyStats = createAction(AnalyticsAction.FetchWeeklyStats, props<{ firstDayWeek: string; amountWeeks: number }>());
export const fetchDailyStats = createAction(AnalyticsAction.FetchDailyStats, props<{ from: string; to: string }>());
export const fetchWeeklyPickingPointStats = createAction(
  AnalyticsAction.FetchWeeklyPickingPointStats,
  props<{ firstDayWeek: string; amountWeeks: number; pickingPointId: number }>()
);
export const fetchMonthlyStats = createAction(AnalyticsAction.FetchMonthlyStats, props<{ firstDayMonth: string; lastDay: string }>());
export const fetchMonthlyForecast = createAction(AnalyticsAction.FetchMonthlyForecast, props<{ id?: string }>());
export const fetchMonthlyPickingPointStats = createAction(
  AnalyticsAction.FetchMonthlyPickingPointStats,
  props<{ firstDayMonth: string; lastDay: string; pickingPointId: number }>()
);
export const fetchDailyPickingPointStats = createAction(
  AnalyticsAction.FetchDailyPickingPointStats,
  props<{ from: string; to: string; pickingPointId: number }>()
);
export const setData = createAction(AnalyticsAction.SetData, props<{ stats: AnalyticsStat[]; histograms: AnalyticsHistogram[] }>());
export const setFilters = createAction(AnalyticsAction.SetFilters, props<{ pickingPointId: number | undefined; range: PossibleRange }>());
export const setForecast = createAction(AnalyticsAction.SetForecast, props<Forecast>());
export const modifyFilters = createAction(
  AnalyticsAction.ModifyFilters,
  props<{ pickingPointId: number | undefined; range: PossibleRange }>()
);
