import { StoreKeeperProps } from "@app/modules/orders/entities/orders.entities";
import { DropOff, PickingPoint } from "@core/entities";
import { PaymentMethodLabel } from "../constants";

export enum ActionType {
  "PICK_UP" = "PICK_UP",
  "DROP_OFF" = "DROP_OFF",
}

export enum VehicleType {
  "CAR" = "CAR",
  "BIKE" = "BIKE",
}

export enum LocationType {
  "STORE" = "STORE",
  "CLIENT" = "CLIENT",
}

export enum PaymentMethod {
  "CASH" = "CASH",
  "ONLINE" = "ONLINE",
  "TERMINAL" = "TERMINAL",
}

export enum OrderStopType {
  "ARBITRARY" = "ARBITRARY",
  "PICKING_POINT" = "PICKING_POINT",
}

export enum OrderType {
  "BASIC" = "BASIC",
  "INVERSE" = "INVERSE",
}
export interface Coords {
  lat: number;
  lng: number;
}

export interface DispatchData {
  view: string;
  props: DispatchProps;
}

export interface DispatchProps {
  markers: MarkerProps[];
  storeKeeper?: StoreKeeperProps;
  markerResult?: (dragged: boolean, coords: Coords, address: string, index: number) => any;
  coordsCenter: Coords;
  mapStyles: object;
  renderRoute?: boolean;
}

export enum MarkerIcon {
  origin = "origin",
  destination = "destination",
}

export interface MarkerProps {
  coords?: Coords;
  draggable?: boolean;
  icon: MarkerIcon;
}

export interface User {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
}

export interface CreateOrderReducerState {
  details?: DeliveryDetails;
  step: number;
  orderId?: string;
  rappiOrderId?: string;
  ui: CreateOrderUi;
  user?: User;
  totalValue: number;
  deliveryTypes: ServiceType[];
  estimatedPrice?: number;
  terminalPickingPoint?: number;
}

export interface CreateOrderUi {
  loaderBtn?: boolean;
  openMainPPPickerModal?: boolean;
}

export interface PropsUi {
  step?: number;
  phoneLength: number;
  expandSummary?: boolean;
  rappiOrderId?: string;
}

export interface DeliveryDetails {
  serviceType?: ServiceType;
  needs_car?: boolean;
  tip?: number;
  return_enabled?: boolean;
  preparation_time?: number;
  deliver_at?: string;
  paymentMethod: PaymentMethodLabel | null;
}

export type BaseActionPoint<T> = {
  instructions: string;
  products: T[];
};
export interface Product {
  id: string;
  units: number;
  unit_price: number;
  external_id: string;
  name: string;
  description?: string;
}

// para uso interno
export type PickingPointStop = BaseActionPoint<Product> & {
  pickingPoint: PickingPoint;
};

export type ArbitraryLocation = DropOff & User;

export type ArbitraryStop = Partial<ArbitraryLocation> & BaseActionPoint<Product>;
export interface OrderStopPickingPoint {
  id: number;
  stopType: OrderStopType.PICKING_POINT;
  data: PickingPointStop;
}
export interface OrderStopDropoff {
  id: number;
  stopType: OrderStopType.ARBITRARY;
  data: ArbitraryStop | null;
}

export type OrderStop = OrderStopPickingPoint | OrderStopDropoff;
export interface OrderStopModifier {
  id: number;
  changes: Partial<PickingPointStop>;
}

export function isArbitraryStop(o: OrderStop): o is OrderStopDropoff {
  return o?.stopType === OrderStopType.ARBITRARY;
}
export interface OrderForCreation {
  custom_id: string;
  details: DeliveryDetails;
  user: User;
  stops: OrderStop[];
  total_value: number;
}

export type PickingPointStopV3 = BaseActionPoint<ProductV3> & {
  picking_point_id: number;
  external_picking_point_id: string;
  action_type: ActionType;
  location_type: LocationType;
};

export type ArbitraryStopV3 = BaseActionPoint<ProductV3> & {
  action_type: ActionType;
  location_type: LocationType;
};

export interface OrderV3 {
  total_value: number;
  user_tip: number;
  vehicle_type: VehicleType;
  payment_method: PaymentMethod;
  action_points: (PickingPointStopV3 | ArbitraryStopV3)[];
  in_store_reference_id: string;
  order_id: string;
  client_info: User & DropOff;
  deliver_at: string;
  preparation_time?: number;
}

export interface ProductV3 {
  units: number;
  unit_price: number;
  product_id: string;
  name: string;
  description?: string;
}

export interface DeliveryServicesReq {
  user: User;
  stops: OrderStop[];
  total_value: number;
}

export enum MethodTypes {
  Save = "save",
  Create = "create",
}

export interface DeliveryState {
  deliveryTypes: ServiceType[];
}
export interface ServiceType {
  type: string;
  name: string;
  eta: string;
  etaInformation?: IEtaInformation;
}
export interface IEtaInterval {
  lower: number;
  upper: number;
}
export interface IEtaInformation {
  eta: number;
  etaInterval: IEtaInterval;
}
export class EtaInformation {
  etaInformation: IEtaInformation;
  constructor(eta: number, etaInterval: IEtaInterval) {
    this.etaInformation = {
      eta,
      etaInterval,
    };
  }
}
