export const STYLES = {
  dev: {
    wc_styles: {
      name: "wc_styles",
      src: "https://mf-google-maps.dev.rappi.com/static/css/bundle.min.css",
      type: "text/css",
    },
  },
  prod: {
    wc_styles: {
      name: "wc_styles",
      src: "https://mf-google-maps.rappi.com/static/css/bundle.min.css",
      type: "text/css",
    },
  },
  local: {
    wc_styles: {
      name: "wc_styles",
      src: "http://localhost:5000/static/css/bundle.min.css",
      type: "text/css",
    },
  },
};
