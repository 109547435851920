import * as LandingAction from "@modules/landing/store/actions/landing.action";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { LandingState } from "../../entities";

interface ReducerState extends LandingState {}

const initialState: ReducerState = {};

const landingReducer = createReducer(
  initialState,
  on(LandingAction.eraseData, (state) => ({ ...state, eraseData: performance.now() })),
);

export function LandingReducer(state: ReducerState, action: Action) {
  return landingReducer(state, action);
}

export const getLanding = createFeatureSelector<ReducerState>("landing");

export const getLogs = createSelector(getLanding, ({ eraseData }) => eraseData);
