import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, CanLoad, Router } from "@angular/router";
import { AuthFacade } from "@app/modules/auth/facade/auth.facade";

import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class AuthenticationGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private router: Router, private authModel: AuthFacade) {}

  private getStateAuth(): Observable<boolean> {
    return this.authModel.getAuth().pipe(
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(["/auth/login"]);
        }
      }),
      map(authenticated => authenticated)
    );
  }

  canLoad(): Observable<boolean> {
    return this.getStateAuth();
  }

  canActivate(): Observable<boolean> {
    return this.getStateAuth();
  }

  canActivateChild(): Observable<boolean> {
    return this.getStateAuth();
  }
}
