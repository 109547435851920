import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

export class CustomHttpTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, public url: string, public fallback: string) {}

  getTranslation = (): Observable<File> =>
    this.http.get<File>(this.url).pipe(catchError((): Observable<File> => this.http.get<File>(this.fallback)));
}
