import * as DevPortalAction from "@modules/dev-portal/store/actions/dev-portal.action";
import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { DevPortalReducerState } from "../../entities";

interface ReducerState extends DevPortalReducerState {}

const initialState: ReducerState = {
  orders: [],
  logs: [],
  selectedOrder: undefined,
  ui: {
    isLoading: false,
  },
};

const devPortalReducer = createReducer(
  initialState,
  on(DevPortalAction.clearAll, () => initialState),
  on(DevPortalAction.setOrders, (state, { orders }) => ({ ...state, orders })),
  on(DevPortalAction.setSelectedOrder, (state, { selectedOrder }) => ({ ...state, selectedOrder })),
  on(DevPortalAction.setLogs, (state, { logs }) => ({ ...state, logs })),
  on(DevPortalAction.setUi, (state, { ui }) => ({ ...state, ui: { ...state.ui, ...ui } }))
);

export function DevPortalReducer(state: ReducerState, action: Action) {
  return devPortalReducer(state, action);
}

export const getDevPortal = createFeatureSelector<ReducerState>("devPortal");

export const getOrders = createSelector(getDevPortal, ({ orders }) => orders);
export const getSelectedOrder = createSelector(getDevPortal, ({ selectedOrder }) => selectedOrder);
export const getLogs = createSelector(getDevPortal, ({ logs }) => logs);
export const getUi = createSelector(getDevPortal, ({ ui }) => ui);
