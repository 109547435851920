import { environment } from "src/environments/environment";

const checkinAttributes = {
  es: {
    checkin_code: "CODIGO",
    external_id: "ID_TIENDA_INTERNO",
    id: "ID_TIENDA_CARGO",
    name: "NOMBRE",
    checkin_code_url: "URL"
  },
  pt: {
    checkin_code: "CODIGO",
    external_id: "ID_LOJA_INTERNO",
    id: "ID_LOJA_CARGO",
    name: "NOMEM",
    checkin_code_url: "URL"
  },
};

export function translateCheckinAttributes(att: string) {
  const { lang } = environment;
  return checkinAttributes[lang][att];
}
