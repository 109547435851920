import { Mutator } from "@core/utils/mutator.util";

export interface AdapterUi<T> {
  name: keyof T;
  value: any;
}

export class GlobalAdapter {
  static setUi<T>(payload: Array<AdapterUi<T>>, state) {
    const ui = Mutator.copy(state.ui);
    payload.forEach(({ name, value }) => (ui[`${String(name)}`] = value));
    return { ...state, ui };
  }
}
