import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { environment as ENV } from "@environments/environment";
import { Observable, of } from "rxjs";

@Injectable()
export class RegionGuard implements CanActivate {
  constructor(protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const unavailableRegions: string[] = route.data.unavailableRegions as string[];

    const unavailable = !unavailableRegions.includes(ENV.code);
    if (!unavailable) {
      this.router.navigate(["/dashboard/active-orders"]);
    }
    return of(unavailable);
  }
}
